import styled from '../../../styled-components';

export const TableCell = styled.td`
    padding: 1.2rem 1.5rem;
`;

export const TableActionCell = styled(TableCell)`
    text-align: right;
`;

export const TableRow = styled.tr``;

export const Table = styled.table`
    border-spacing: 0;
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 3px;
    overflow: hidden;

    thead ${TableCell} {
        background: ${({ theme }) => theme.colors.darkGreen};
        font-weight: 600;
        padding: 1.5rem 1.5rem 1rem;
        color: white;
    }

    tbody tr:hover td {
        background: ${({ theme }) => theme.colors.primary.lightGrey.color};
    }

    tbody tr:nth-child(odd) ${TableCell} {
        background: white;
    }

    tbody tr:nth-child(even) ${TableCell} {
        background: #f9f9f9;
    }
`;
