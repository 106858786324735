const dev = {
    API_KEY: 'AIzaSyCk3uOYepX31PXC0YNReKxqdO5iiG_i8II',
    AUTH_DOMAIN: 'ourblock-dev.firebaseapp.com',
    DATABASE_URL: 'https://ourblock-dev.firebaseio.com',
    PROJECT_ID: 'ourblock-dev',
    STORAGE_BUCKET: 'ourblock-dev.appspot.com',
    MESSAGING_SENDER_ID: '155677343383',
    APP_ID: '1:155677343383:web:c25de89e49958763bcf3d2',
    MODE: 'dev',
    SENTRY_DSN: 'https://7eb71d4ed9f746cfac206bc10b8213a7@sentry.io/1862570',
};

const staging = {
    API_KEY: 'AIzaSyDhesIMlLyVFFOmSH5doHNDvkT0fg7-zUs',
    AUTH_DOMAIN: 'ourblock-staging.firebaseapp.com',
    DATABASE_URL: 'https://ourblock-staging.firebaseio.com',
    PROJECT_ID: 'ourblock-staging',
    STORAGE_BUCKET: 'ourblock-staging.appspot.com',
    MESSAGING_SENDER_ID: '138278339707',
    APP_ID: '1:138278339707:web:bf33e73e0867975c',
    MODE: 'dev',
    SENTRY_DSN: 'https://7eb71d4ed9f746cfac206bc10b8213a7@sentry.io/1862570',
};

const prod = {
    API_KEY: 'AIzaSyDNADqwm_bvU3YQM8DPvvGgDcCOySCoL6I',
    AUTH_DOMAIN: 'parcel-4554d.firebaseapp.com',
    DATABASE_URL: 'https://parcel-4554d.firebaseio.com',
    PROJECT_ID: 'parcel-4554d',
    STORAGE_BUCKET: 'parcel-4554d.appspot.com',
    MESSAGING_SENDER_ID: '585371947180',
    APP_ID: '',
    MODE: 'prod',
    SENTRY_DSN: 'https://5d6748a0031b4bf09d87b63720bdb775@sentry.io/1862586',
};

function getConfig(stage: 'production' | 'staging' | 'dev') {
    switch (stage) {
        case 'production':
            return prod;
        case 'staging':
            return staging;
        case 'dev':
            return dev;
    }
}

const config = getConfig(
    process.env.REACT_APP_STAGE as 'production' | 'staging' | 'dev'
);
export default {
    ...config,
};
