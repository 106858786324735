import React from 'react';
import styled from '../../styled-components';
import SignaturePad from 'signature_pad';
import { pixelToRem } from '../../utilities';

interface ISignaturePadProps extends parcel.IBaseStyledComponent {
    onSignature: (signatureData: any) => void;
}

interface ISignaturePadState {
    pristine: boolean;
}

const Canvas = styled.canvas`
    border: solid 1px ${({ theme }) => theme.colors.primary.grey.color};
    border-radius: 3px;
    width: 100%;
    height: 55vh;
`;

const Message = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
`;

class UnstyledParcelSignaturePad extends React.Component<
    ISignaturePadProps,
    ISignaturePadState
> {
    public state: ISignaturePadState = {
        pristine: true,
    };
    public pad: SignaturePad = null as any;
    public canvas: React.RefObject<HTMLCanvasElement> = React.createRef<
        HTMLCanvasElement
    >();

    public componentDidMount() {
        this.pad = new SignaturePad(this.canvas.current as HTMLCanvasElement, {
            onEnd: this.onEnd,
            onBegin: this.onBegin,
        });

        this.resizeCanvas();
    }

    public render() {
        const { className } = this.props;
        return (
            <div className={className}>
                <Canvas ref={this.canvas} />
                {this.state.pristine && <Message>Sign here</Message>}
            </div>
        );
    }

    private onBegin = () => {
        if (this.state.pristine) {
            this.setState({
                pristine: false,
            });
        }
    };

    private onEnd = () => {
        if (this.pad.isEmpty()) {
            return;
        }

        this.props.onSignature(this.pad.toDataURL());
    };

    private resizeCanvas = () => {
        let canvas = this.canvas.current as HTMLCanvasElement;
        /* When zoomed out to less than 100%, for some very strange reason,
          some browsers report devicePixelRatio as less than 1
          and only part of the canvas is cleared then. */
        let ratio = Math.max(window.devicePixelRatio || 1, 1);

        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        (canvas.getContext('2d') as CanvasRenderingContext2D).scale(
            ratio,
            ratio
        );
        this.pad.clear();
    };
}

export const ParcelSignaturePad = styled(UnstyledParcelSignaturePad)`
    position: relative;
    margin-bottom: ${pixelToRem(20)};
`;
