import React from 'react';
import { createGlobalStyle } from './styled-components';
import { Normalize } from 'styled-normalize';
import theme from './theme';
import { media } from './utilities';

const BodyStyles = createGlobalStyle`
    html,
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100%;
        font-size: 10px;
    }

    #root {
        height: 100%;
    }

    * {
        box-sizing: border-box;
    }

    body {
        font-family: ${theme.font.type.primary};
        line-height: 1;
        color: ${theme.colors.dark};
        background: ${({ theme }) => theme.colors.background};

        ${media.m`
            font-size: ${theme.font.sizing.primary.level5.desktop};
        `}
    }

    img {
        width: 100%;
    }

    input {
        border-radius: 0;
    }
`;

const MarginReset = createGlobalStyle`
    h1, h2, h3, h4, h5, h6, p {
        margin-top: 0;
    }
`;

export default () => (
    <>
        <Normalize />
        <BodyStyles />
        <MarginReset />
    </>
);
