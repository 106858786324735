import React, { useContext, useState, useEffect } from 'react';
import { parcelsApi } from '../../api';
import { residencesApi } from '../../api/resources/residences';
import { BuildingContext } from '../../context/BuildingContext';
import AddNewParcel from './AddNewParcel';

const AddNewParcelContainer: React.SFC = () => {
    const building = useContext(BuildingContext);
    const [residences, setResidences] = useState<parcel.IResidence[] | null>(
        null
    );

    useEffect(() => {
        async function getResidences() {
            setResidences(await residencesApi.get(building.id));
        }

        if (building) {
            getResidences();
        }
    }, [building]);

    const addParcel = (parcel: parcel.ICreateParcel) => {
        parcelsApi.create(building.id, parcel);
    };

    return (
        residences && (
            <AddNewParcel addParcel={addParcel} residences={residences} />
        )
    );
};

export default AddNewParcelContainer;
