import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
    memo,
} from 'react';
import styled from '../../styled-components';
import { FormRow, Input, FormRowItem } from '../ui';
import { media, pixelToRem } from '../../utilities';
import {
    SearchSelect,
    ISearchSelectItem,
} from '../SearchSelect/SearchSelect.component';
import { mapResidencesToSelectItems } from '../SearchSelect/mapResidencesToSelectItems';
import { Button } from '../Form';
import { usersApi } from '../../api';

const QuantityFormRowItem = styled(FormRowItem)`
    ${media.m`
        max-width: ${pixelToRem(100)};
    `}
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const ErrorMessage = styled.span`
    color: #ff3333;
    padding: 1rem;
`;

const SuccessfulSubmission = styled.div`
    background-color: ${({ theme }) => theme.colors.positive.green.color};
    color: white;
    border-radius: 3px;
    padding: ${pixelToRem(5)};
    margin-bottom: ${pixelToRem(10)};
`;

const RecipientInstruction = styled.div`
    padding: ${pixelToRem(8)} ${pixelToRem(4)};
    line-height: 1.15;
    border-bottom: solid 1px ${({ theme }) => theme.colors.primary.grey.color};
`;

const Wrapper = styled.div`
    margin-bottom: 2rem;
    background: white;
    border-radius: 3px;
    padding: 2rem;
    margin-bottom: 4rem;
`;

type Props = {
    residences: parcel.IResidence[];
    addParcel: (partialParcel: parcel.ICreateParcel) => void;
};

const AddNewParcel: FunctionComponent<Props> = ({ residences, addParcel }) => {
    const residenceOptions = mapResidencesToSelectItems(residences);
    const [usersInResidence, setUsersInResidence] = useState<
        parcel.User[] | null
    >(null);
    const [userOptions, setUserOptions] = useState<ISearchSelectItem[] | null>(
        null
    );

    const [submissionSuccessful, setSubmissionSuccessful] = useState<boolean>(
        false
    );
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [formIsValid, setFormIsValid] = useState<boolean>(true);

    const [quantity, setQuantity] = useState<number>(1);
    const [recipient, setRecipient] = useState<string | parcel.User | null>(
        null
    );
    const [
        selectedResidence,
        setSelectedResidence,
    ] = useState<parcel.IResidence | null>(null);

    useEffect(() => {
        async function fetchUsersForResidence(
            selectedResidence: parcel.IResidence
        ) {
            const users = await usersApi.getUsersInResidence(
                selectedResidence.id
            );
            setUsersInResidence(
                await usersApi.getUsersInResidence(selectedResidence.id)
            );
            setUserOptions(
                users.map((user) => ({
                    value: user.id,
                    label: `${user.firstName} ${user.lastName}`,
                }))
            );
        }

        if (selectedResidence) {
            fetchUsersForResidence(selectedResidence);
        }
    }, [selectedResidence]);

    const renderRecipientSelection = useCallback(() => {
        if (
            usersInResidence &&
            userOptions &&
            userOptions.length > 0 &&
            typeof recipient !== 'string'
        ) {
            return (
                <SearchSelect
                    items={userOptions}
                    handleSelect={(selectedItem) => {
                        setRecipient(
                            usersInResidence.find(
                                (user) => user.id === selectedItem.value
                            ) as parcel.User
                        );
                    }}
                    value={
                        (recipient &&
                            typeof recipient === 'object' &&
                            userOptions.find(
                                (option) => option.value === recipient.id
                            )) ||
                        null
                    }
                    label="Recipient*:"
                    placeholder="Search for a resident"
                    handleAddSelect={(input) => setRecipient(input as string)}
                />
            );
        } else {
            return (
                <>
                    <label>Recipient*:</label>

                    <Input
                        type="text"
                        name="recipientName"
                        placeholder="Manually enter recipient"
                        value={(recipient as string) || ''}
                        onChange={(e) => setRecipient(e.target.value)}
                        hasValue={
                            (recipient &&
                                typeof recipient === 'string' &&
                                recipient.length > 0) ||
                            false
                        }
                    />
                </>
            );
        }
    }, [recipient, usersInResidence, userOptions]);

    const showSuccess = () => {
        setSubmissionSuccessful(true);
        setTimeout(() => setSubmissionSuccessful(false), 5000);
    };

    const validateForm = useCallback(() => {
        if (selectedResidence === null || recipient === null || quantity <= 0) {
            setFormIsValid(false);
            return false;
        } else {
            setFormIsValid(true);
            return true;
        }
    }, [selectedResidence, recipient, quantity]);

    const clearForm = () => {
        setSelectedResidence(null);
        setFormIsValid(true);
        setRecipient(null);
        setQuantity(1);
    };

    const saveParcel = useCallback(() => {
        setSubmitted(true);

        if (!validateForm()) {
            return;
        }

        if (selectedResidence === null) {
            throw new Error('No residence selected');
        } else if (recipient === null) {
            throw new Error('No recipient set');
        }

        addParcel({
            recipient: recipient,
            residence: selectedResidence,
            quantity,
        });

        showSuccess();
        clearForm();
    }, [quantity, selectedResidence, recipient, addParcel, validateForm]);

    return (
        <Wrapper>
            {submissionSuccessful && (
                <SuccessfulSubmission>
                    Parcel submitted successfully.
                </SuccessfulSubmission>
            )}
            <FormRow>
                <FormRowItem>
                    <SearchSelect
                        items={residenceOptions}
                        handleSelect={(selectedValue) => {
                            setSelectedResidence(
                                residences.find(
                                    (r) =>
                                        r.id ===
                                        (selectedValue && selectedValue.value)
                                )!
                            );

                            setRecipient(null);
                        }}
                        value={
                            residenceOptions.find(
                                (option) =>
                                    option.value === selectedResidence?.id
                            ) || null
                        }
                        label="Residence*:"
                        placeholder="Search for a residence"
                    />
                </FormRowItem>

                <QuantityFormRowItem>
                    <label>Quantity*:</label>
                    <Input
                        type="number"
                        name="quantity"
                        value={quantity}
                        onChange={(changeEvent) =>
                            setQuantity(changeEvent.target.value as any)
                        }
                        hasValue={selectedResidence !== null}
                    />
                </QuantityFormRowItem>

                <FormRowItem>
                    {(selectedResidence && renderRecipientSelection()) || (
                        <>
                            <label>Recipient*:</label>
                            <RecipientInstruction>
                                Select a residence to find a recipient
                            </RecipientInstruction>
                        </>
                    )}
                </FormRowItem>
            </FormRow>

            <ButtonWrapper>
                {submitted && !formIsValid && !submissionSuccessful && (
                    <ErrorMessage>Please complete all fields</ErrorMessage>
                )}
                <Button onClick={saveParcel}>Add</Button>
            </ButtonWrapper>
        </Wrapper>
    );
};

export default memo(AddNewParcel);
