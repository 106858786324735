import React from 'react';
import LoginForm from '../components/LoginForm/LoginForm.container';
import { RouteProps, Route, Redirect } from 'react-router';
import styled from '../styled-components';

export const LoginWrapper = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
`;

interface ILoginRouteProps extends RouteProps {
    authenticated: boolean;
}

const Login = ({ authenticated, ...rest }: ILoginRouteProps) => {
    return (
        <Route
            {...rest}
            render={() => (
                <>
                    {authenticated && <Redirect to="/" />}

                    <LoginWrapper>
                        <LoginForm />
                    </LoginWrapper>
                </>
            )}
        />
    );
};

export default Login;
