import React, { useState } from 'react';
import { FormRow, FormRowItem, Heading3 } from '../ui';
import styled from '../../styled-components';
import { pixelToRem } from '../../utilities';
import { resetPassword } from '../../auth';
import { TextInput, Button } from '../Form';

enum FORM_STATE {
    pristine,
    completed,
}

const Header = styled(Heading3.withComponent('h1'))`
    margin-bottom: 4rem;
`;

const UstyledResetPasswordForm: React.FC<parcel.IBaseStyledComponent> = ({
    className,
}) => {
    const [email, setEmail] = useState<string>('');
    const [state, setState] = useState<FORM_STATE>(FORM_STATE.pristine);

    const onSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (email.length < 3) {
            return;
        }

        await resetPassword(email);
        setState(FORM_STATE.completed);
    };

    return (
        <form className={className} onSubmit={onSubmit}>
            <Header>Reset password</Header>

            {state === FORM_STATE.pristine && (
                <>
                    <FormRow>
                        <FormRowItem>
                            <TextInput
                                type="email"
                                label="Email:"
                                value={email}
                                onChange={e => {
                                    setEmail(e.target.value);
                                }}
                                required
                            />
                        </FormRowItem>
                    </FormRow>

                    <Button type="submit">Submit</Button>
                </>
            )}

            {state === FORM_STATE.completed && (
                <p>
                    Thank you, if you have an account then you'll receive a
                    reset password email shortly.
                </p>
            )}
        </form>
    );
};

export const ResetPasswordForm = styled(UstyledResetPasswordForm)`
    width: 100%;
    max-width: ${pixelToRem(400)};
    padding: ${pixelToRem(30)};
    border: solid 1px ${({ theme }) => theme.colors.primary.blue.color};
    border-radius: 5px;
    background: white;
`;
