import React, { useState } from 'react';
import styled from '../../styled-components';
import { Heading3, Heading1, Constrain, LinkButton } from '../ui';
import { useContext } from 'react';
import { BuildingContext } from '../../context/BuildingContext';
import { pixelToRem } from '../../utilities';
import { logoutUser } from '../../api/auth';
import { HeadingLevel1, Link } from '../../ui-components';
import { Switch } from '../Form';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';

const Nav = styled.nav`
    text-align: right;
    margin-right: ${pixelToRem(20)};

    & > * {
        margin: ${pixelToRem(10)};
        display: inline-block;
        color: ${({ theme }) => theme.colors.dark};
    }

    & > *:last-child {
        margin-right: 0;
    }
`;

const Inner = styled(Constrain)`
    display: flex;
    align-items: center;

    & > *:first-child {
        margin-right: auto;
    }
`;

const UnstyledMasthead: React.SFC<
    { pageTitle?: string } & parcel.IBaseStyledComponent
> = ({ pageTitle, className }) => {
    const building = useContext(BuildingContext);
    const [redirect, setRedirect] = useState<string>();
    const location = useLocation();

    const onContextToggle = (isUserContext: boolean) => {
        const route = isUserContext ? '/' : '/concierge';
        setRedirect(route);
    };

    return (
        <header className={className}>
            {redirect && <Redirect to={redirect} />}

            <Inner>
                <HeadingLevel1>{building.name}</HeadingLevel1>

                {location.pathname !== '/' && (
                    <Nav>
                        <Link to="/concierge">Add parcel</Link>
                        <Link to="/residences">Residences</Link>
                        <LinkButton onClick={logoutUser}>Logout</LinkButton>
                    </Nav>
                )}

                <Switch
                    onToggle={onContextToggle}
                    enabled={location.pathname !== '/'}
                />
            </Inner>

            {pageTitle && (
                <Constrain>
                    <Heading3 dark>{pageTitle}</Heading3>
                </Constrain>
            )}
        </header>
    );
};

export const Masthead = styled(UnstyledMasthead)`
    background: white;
    color: ${({ theme }) => theme.colors.primary.dark.contrast};

    padding: ${pixelToRem(10)} 0;

    ${Heading1} {
        font-size: ${pixelToRem(32)};
        color: white;
    }
`;
