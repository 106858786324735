import React, { createContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface IPrivateRouteProps extends RouteProps {
    component: any;
    authenticated: boolean;
    building: parcel.IBuilding;
}

export const BuildingContext = createContext<parcel.IBuilding>({} as any);

const PrivateRoute: React.SFC<IPrivateRouteProps> = ({
    component: Component,
    authenticated,
    building,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                authenticated === true ? (
                    <BuildingContext.Provider value={{ ...building }}>
                        <Component {...props} {...rest} />
                    </BuildingContext.Provider>
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default PrivateRoute;
