import styled, { css } from '../../../styled-components';
import { media } from '../../../utilities';

interface IHeadingOptions {
    noMargin?: boolean;
    dark?: boolean;
}

const BaseHeadingStyles = css<IHeadingOptions>`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.darkGreen};

    ${({ noMargin }) =>
        noMargin
            ? css`
                  margin-bottom: 0;
              `
            : ''}
`;

export const Heading1 = styled.h1`
    ${BaseHeadingStyles};

    font-size: ${({ theme }) => theme.font.sizing.primary.level1.mobile};

    ${media.m`
        font-size: ${({ theme }) => theme.font.sizing.primary.level1.desktop};
    `}
`;

export const Heading2 = styled.h2`
    ${BaseHeadingStyles};

    font-size: ${({ theme }) => theme.font.sizing.primary.level2.mobile};

    ${media.m`
        font-size: ${({ theme }) => theme.font.sizing.primary.level2.desktop};
    `}
`;

export const Heading3 = styled.h3`
    ${BaseHeadingStyles};

    font-size: ${({ theme }) => theme.font.sizing.primary.level3.mobile};

    ${media.m`
        font-size: ${({ theme }) => theme.font.sizing.primary.level3.desktop};
    `}
`;

export const Heading4 = styled.h4`
    ${BaseHeadingStyles};

    font-size: ${({ theme }) => theme.font.sizing.primary.level4.mobile};
    margin-bottom: 1rem;

    ${media.m`
        font-size: ${({ theme }) => theme.font.sizing.primary.level4.desktop};
    `}
`;
