import React, { useState } from 'react';
import styled from '../../styled-components';
import { ParcelSignaturePad } from '../SignaturePad/SignaturePad.component';
import { ICollectParcelsSubmitData } from './CollectParcelForm.container';
import { pixelToRem } from '../../utilities';
import { Button } from '../ui';

interface ICollectParcelFormProps extends parcel.IBaseStyledComponent {
    submitCollection: (data: ICollectParcelsSubmitData) => Promise<null>;
}

const Error = styled.div`
    background-color: ${({ theme }) => theme.colors.negative.red.color};
    color: white;
    border-radius: 3px;
    padding: ${pixelToRem(5)} ${pixelToRem(10)};
    margin-bottom: ${pixelToRem(10)};
`;

const Disclaimer = styled.p`
    font-size: 12px;
    margin-bottom: ${pixelToRem(5)};
`;

const SignPrompt = styled.span`
    color: ${({ theme }) => theme.colors.primary.grey.color};
    font-size: 14px;
    margin-left: 1rem;
`;

const Actions = styled.div`
    padding: ${pixelToRem(10)} 0;
`;

const isFormValid = (signatureData: string): boolean => {
    return signatureData.length > 0;
};

export const CollectParcelForm: React.FC<ICollectParcelFormProps> = ({
    submitCollection,
}) => {
    const [formError, setFormError] = useState<string>('');
    const [signatureData, setSignatureData] = useState<string>('');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const onSignature = (signatureData: string) =>
        setSignatureData(signatureData);

    const submit = async () => {
        if (!isFormValid(signatureData)) {
            return;
        }

        setSubmitting(true);

        try {
            await submitCollection({ signatureData });
            // setSubmitting(false); causes memory warning
        } catch (e) {
            setFormError('Error collecting packages - please try again');
            setSubmitting(false);
        }
    };

    return (
        <div data-testid="collectParcelForm">
            {formError.length > 0 && <Error>{formError}</Error>}

            <ParcelSignaturePad onSignature={onSignature} />

            <Disclaimer>
                Please note that by confirming your collection you are accepting
                our T&Cs of use and that your signature will be encrypted and
                stored on our secure server.
            </Disclaimer>

            <Disclaimer>
                Visit <strong>ourblock.digital</strong> for more information, or
                get in contact with us directly at{' '}
                <strong>hello@ourblock.app</strong>
            </Disclaimer>

            <Actions>
                <Button
                    onClick={submit}
                    disabled={signatureData.length === 0 || submitting}
                    data-testid="confirmParcelCollection"
                >
                    Confirm collection
                </Button>

                {submitting && (
                    <SignPrompt>Submitting collection...</SignPrompt>
                )}

                {signatureData.length === 0 && (
                    <SignPrompt>Sign above to confirm collection</SignPrompt>
                )}
            </Actions>
        </div>
    );
};
