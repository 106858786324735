import React from 'react';
import ReactModal from 'react-modal';
import { Heading3 } from '../../ui';
import styled, { css } from '../../../styled-components';
import { StylelessButton } from '../Button/Button.component';

export interface IModalProps extends ReactModal.Props {
    title?: string | boolean;
    handleModalClose: () => void;
    children?: any;
    fullWidth?: boolean;
}

function ReactModalAdapter({ className, modalClassName, ...props }: any) {
    return (
        <ReactModal
            className={modalClassName}
            portalClassName={className}
            {...props}
        />
    );
}

const ModalHeader = styled.div<{ hasTitle: boolean }>`
    position: relative;
    background: white;
    text-align: center;
    font-weight: 500;
    padding: 1rem;
    border-bottom: solid 2px
        ${({ theme, hasTitle }) =>
            hasTitle
                ? `solid 2px ${theme.colors.primary.lightGrey.color}`
                : `0`};
    min-height: 4.2rem;

    ${Heading3} {
        margin: 0;
        font-weight: 500;
    }
`;

const ModalContent = styled.div`
    padding: 1.5rem;
    background-color: white;
    min-width: 600px;
`;

const overlayClassName = 'modal__overlay';
const modalContentClassName = 'modal__content';

const StyledModal = styled(ReactModalAdapter).attrs({
    overlayClassName: overlayClassName as any,
    modalClassName: modalContentClassName as any,
})`
    .${modalContentClassName} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        opacity: 0;
        max-width: 90vw;
        max-height: 90vh;
        z-index: 300;
        overflow: hidden;
        /* transition: opacity 150ms ease-out; */
        background-color: white;

        ${({ fullWidth }) =>
            fullWidth &&
            css`
                max-width: 100vw;
                max-height: 100vh;
                min-width: 100vw;
                min-height: 100vh;
            `}
    }

    .${overlayClassName} {
        background: rgba(255, 255, 255, 0.5);
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 200;
        transition: opacity 150ms ease-out;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
    }

    .ReactModal__Content--after-open {
        transform: translateY(-50%) translateX(-50%);
        opacity: 1;
        /* transition: opacity 150ms ease-in; */
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
    }

    .ReactModal__Content--before-close {
        transform: translateY(-50%) translateX(-50%);
        /* transition: opacity 150ms ease-in; */
    }
`;

const ModalInner = styled.div`
    position: relative;
    padding: 3rem;
`;

export const ModalActions = styled.div<{ align?: 'left' | 'center' | 'right' }>`
    text-align: ${({ align }) => align || 'center'};
    margin-top: 3rem;

    & > *:not(:first-child) {
        margin-left: 1.5rem;
    }
`;

const ModalClose = styled(StylelessButton)`
    position: absolute;
    right: 3rem;
    top: 3rem;
`;

const CloseSVG = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 7 7"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M.37 5.98l2.5-2.5L.37.98a.44.44 0 0 1 0-.62.44.44 0 0 1 .62 0l2.5 2.5L6 .36a.44.44 0 0 1 .62.62l-2.5 2.5 2.5 2.5c.17.17.18.45 0 .62a.44.44 0 0 1-.61 0L3.5 4.1 1 6.6a.44.44 0 0 1-.63 0 .44.44 0 0 1 0-.62z"
            fill="#084557"
            fillRule="nonzero"
        />
    </svg>
);

export const Modal = ({ ...props }: IModalProps) => (
    <StyledModal {...props}>
        <ModalHeader hasTitle={!!props.title}>
            {props.title && <Heading3 dark>{props.title}</Heading3>}

            <ModalClose onClick={props.handleModalClose}>
                <CloseSVG />
            </ModalClose>
        </ModalHeader>

        <ModalContent>{props.children}</ModalContent>
    </StyledModal>
);

const SimpleModalInner = styled.div`
    max-width: 55rem;
    margin: 0 auto;
    position: relative;
`;

export const SimpleModal = ({ ...props }: IModalProps) => (
    <StyledModal {...props} fullWidth>
        <ModalInner>
            <ModalClose onClick={props.handleModalClose}>
                <CloseSVG />
            </ModalClose>

            <SimpleModalInner>{props.children}</SimpleModalInner>
        </ModalInner>
    </StyledModal>
);
