import React, { useState, useEffect, useContext } from 'react';
import { BuildingContext } from '../../context/BuildingContext';
import { residencesApi } from '../../api';
import { SelectAResidence } from './SelectAResidence.component';

export default () => {
    const building = useContext(BuildingContext);
    const [residences, setResidences] = useState<parcel.IResidence[]>([]);

    useEffect(() => {
        const getData = async () => {
            setResidences(await residencesApi.get(building.id));
        };

        getData();
    }, [building.id]);

    return <SelectAResidence residences={residences} />;
};
