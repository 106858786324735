import React from 'react';
import styled from '../../styled-components';

export const Masthead = styled(({ className, children }) => (
    <header className={className}>{children}</header>
))`
    background: white;
    padding: 1.5rem 5rem;
    display: flex;
    justify-content: space-between;
`;
