import React from 'react';
import styled from '../../styled-components';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/hamburger.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';

const Input = styled.input`
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
    left: -999px;
`;

const Toggle = styled.span<{ enabled: boolean }>`
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.darkGreen};
    position: absolute;
    left: 0;
    transform-origin: center center;
    transform: ${({ enabled }) =>
        enabled ? `translateX(80%)` : `translateX(-20%)`};
    transition: transform 100ms;
`;

const Track = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 24px;
    height: 6px;
    background-color: #e0e0e0;
    border-radius: 3px;
    margin: 0 1.5rem;
`;

interface SwitchProps extends parcel.IBaseStyledComponent {
    enabled: boolean;
    onToggle: (switchValue: boolean) => void;
}

export const Switch = styled(
    ({ className, onToggle, enabled }: SwitchProps) => {
        const onChange = () => {
            onToggle(enabled);
        };

        return (
            <label
                className={className}
                htmlFor="switch-01"
                data-testid="contextToggle"
            >
                <Input
                    type="checkbox"
                    checked={enabled}
                    onChange={onChange}
                    id="switch-01"
                />

                <UserIcon className="user-icon" />

                <Track>
                    <Toggle enabled={enabled} />
                </Track>

                <HamburgerIcon className="hamburger-icon" />
            </label>
        );
    }
)`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    background: #f2f2f2;
    border-radius: 3px;
    padding: 0.8rem 1.1rem;

    svg {
        transition: fill 100ms;
    }

    input:checked ~ .user-icon {
        fill: ${({ theme }) => theme.colors.dark};
    }

    input:checked ~ .hamburger-icon {
        fill: ${({ theme }) => theme.colors.primary.blue.color};
    }

    input:not(:checked) ~ .user-icon {
        fill: ${({ theme }) => theme.colors.primary.blue.color};
    }

    input:not(:checked) ~ .hamburger-icon {
        fill: ${({ theme }) => theme.colors.dark};
    }
`;
