import React from 'react';
import { Route, RouteProps } from 'react-router';
import { ResetPasswordForm } from '../components/ResetPasswordForm/ResetPasswordForm.component';
import { LoginWrapper } from './LoginRoute';

const PasswordReset = (routeProps: RouteProps) => {
    return (
        <Route
            {...routeProps}
            render={() => {
                return (
                    <LoginWrapper>
                        <ResetPasswordForm />
                    </LoginWrapper>
                );
            }}
        />
    );
};

export default PasswordReset;
