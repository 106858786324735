import React from 'react';
import { LoginForm } from './LoginForm.component';
import { loginUser } from '../../auth';

export default class LoginFormContainer extends React.Component {
    public render() {
        return <LoginForm login={this.login} />;
    }

    private login = async (email: string, password: string) => {
        try {
            await loginUser(email, password);
        } catch (err) {
            throw err;
        }
    };
}
