import React, { useState } from 'react';
import styled from '../../styled-components';
import { Table, TableRow, TableCell } from '../ui';
import { getRecipient } from './getRecipient';
import {
    HeadingLevel2,
    HeadingLevel1,
    SuccessMessage,
    InfoMessage,
} from '../../ui-components';
import { ReactComponent as ParcelSVG } from '../../assets/icons/parcel.svg';
import { ReactComponent as OpenParcelSVG } from '../../assets/icons/external-link.svg';
import { SimpleModal } from '../ui/Modal/Modal.component';
import moment from 'moment';

interface IViewRecentParcelProps {
    groupedParcels: parcel.IGroupedParcel[];
}

const ParcelLineItemHeader = styled(TableRow)`
    ${TableCell} {
        &:nth-child(1) {
            width: 20%;
        }

        &:nth-child(2) {
            width: 30%;
        }

        &:nth-child(3) {
            width: 5%;
        }

        &:nth-child(4) {
            width: 15%;
        }

        &:last-child {
            width: 30%;
        }
    }
`;

const ParcelLineItem = styled.tr`
    padding: 0.4rem 0;

    svg {
        fill: ${({ theme }) => theme.colors.blue};
    }
`;

const DateHeading = styled.h4`
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.dark};
    font-weight: normal;
    margin-bottom: 1.5rem;
`;

const Container = styled.div`
    .heading {
        display: flex;
        margin-bottom: 2rem;

        svg {
            margin-right: 1rem;
        }
    }

    table tbody td:last-child {
        text-align: right;
    }
`;

const ParcelInfoLineItem = styled.li`
    padding: 1.3rem 2rem;
    background-color: white;
    display: flex;
    justify-content: space-between;

    &.signature {
        flex-direction: column;

        span {
            margin-bottom: 1.5rem;
        }
    }
`;

const ParcelInfo = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 3px;
    overflow: hidden;
    border: solid 1px ${({ theme }) => theme.colors.background};

    ${ParcelInfoLineItem}:nth-child(odd) {
        background-color: ${({ theme }) => theme.colors.lightestGrey};
    }
`;

const ViewParcel = styled.div`
    ${HeadingLevel1} {
        margin-bottom: 3rem;
    }

    ${SuccessMessage}, ${InfoMessage} {
        margin-bottom: 2rem;
        min-height: auto;
    }
`;

export const ViewRecentParcels: React.FC<IViewRecentParcelProps> = ({
    groupedParcels,
}) => {
    const [selectedParcel, setSelectedParcel] = useState<parcel.IParcel>();

    return (
        <Container>
            <HeadingLevel2 bold className="heading" data-testid="parcelsHeader">
                <ParcelSVG fill="#333" />
                Parcels
            </HeadingLevel2>

            {groupedParcels.map(({ formattedDate, parcels }, i) => (
                <div key={i}>
                    <DateHeading>{formattedDate}</DateHeading>

                    <Table>
                        <thead>
                            <ParcelLineItemHeader>
                                <TableCell>Residence</TableCell>
                                <TableCell>Recipient</TableCell>
                                <TableCell>Qty</TableCell>
                                <TableCell>Delivered</TableCell>
                                <TableCell colSpan={2}>Collected</TableCell>
                            </ParcelLineItemHeader>
                        </thead>

                        <tbody>
                            {parcels.map((parcel, i) => (
                                <ParcelLineItem
                                    key={i}
                                    onClick={() => setSelectedParcel(parcel)}
                                    data-testid="parcel"
                                >
                                    <TableCell data-testid="residenceNameOrNumber">
                                        {parcel.residence &&
                                            parcel.residence.nameOrNumber}
                                    </TableCell>
                                    <TableCell>
                                        {getRecipient(parcel.recipient)}
                                    </TableCell>
                                    <TableCell>{parcel.quantity}</TableCell>
                                    <TableCell>
                                        {parcel.timeOfArrival.toDate &&
                                            parcel.timeOfArrival
                                                .toDate()
                                                .toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                    </TableCell>
                                    <TableCell>
                                        {parcel.timeOfCollection && (
                                            <>
                                                {parcel.timeOfCollection
                                                    .toDate()
                                                    .toLocaleDateString([], {
                                                        weekday: 'short',
                                                        day: '2-digit',
                                                        month: 'short',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    })}
                                            </>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <OpenParcelSVG />
                                    </TableCell>
                                </ParcelLineItem>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ))}

            <SimpleModal
                isOpen={!!selectedParcel}
                onRequestClose={() => setSelectedParcel(undefined)}
                handleModalClose={() => setSelectedParcel(undefined)}
            >
                {selectedParcel && (
                    <ViewParcel data-testid="parcelInfoModal">
                        <HeadingLevel1>
                            Parcel #{selectedParcel.id}
                        </HeadingLevel1>

                        {selectedParcel.timeOfCollection && (
                            <SuccessMessage>
                                This parcel has been collected.
                            </SuccessMessage>
                        )}

                        {!selectedParcel.timeOfCollection && (
                            <InfoMessage>
                                This parcel is awaiting collection.
                            </InfoMessage>
                        )}

                        <ParcelInfo>
                            <ParcelInfoLineItem>
                                <span>For:</span>
                                <span>
                                    {
                                        (selectedParcel.recipient as parcel.IResident)
                                            .firstName
                                    }{' '}
                                    {
                                        (selectedParcel.recipient as parcel.IResident)
                                            .surname
                                    }
                                </span>
                            </ParcelInfoLineItem>
                            <ParcelInfoLineItem>
                                <span>Residence:</span>
                                <span>
                                    {
                                        (selectedParcel.residence as parcel.IResidence)
                                            .nameOrNumber
                                    }
                                </span>
                            </ParcelInfoLineItem>
                            <ParcelInfoLineItem>
                                <span>Quantity:</span>
                                <span>{selectedParcel.quantity}</span>
                            </ParcelInfoLineItem>
                            <ParcelInfoLineItem>
                                <span>Arrived on:</span>
                                <span>
                                    {moment(
                                        selectedParcel.timeOfArrival.toDate()
                                    ).format('h:mma on Do of MMMM YYYY')}
                                </span>
                            </ParcelInfoLineItem>
                            {selectedParcel.timeOfCollection && (
                                <ParcelInfoLineItem>
                                    <span>Collected on:</span>
                                    <span>
                                        {moment(
                                            selectedParcel.timeOfCollection.toDate()
                                        ).format('h:mma on Do of MMMM YYYY')}
                                    </span>
                                </ParcelInfoLineItem>
                            )}
                            {selectedParcel.signature && (
                                <ParcelInfoLineItem className="signature">
                                    <span>Signature:</span>
                                    <img
                                        src={selectedParcel.signature[0]}
                                        alt="Parcel signature"
                                        data-testid="signature"
                                    />
                                </ParcelInfoLineItem>
                            )}
                        </ParcelInfo>
                    </ViewParcel>
                )}
            </SimpleModal>
        </Container>
    );
};
