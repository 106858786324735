import { pixelToRem } from './utilities';

interface IColor {
    color: string;
    contrast?: string;
}

interface IFontSize {
    mobile: string;
    desktop?: string;
}

export interface IParcelTheme {
    colors: {
        primary: {
            blue: IColor;
            grey: IColor;
            lightGrey: IColor;
            darkGrey: IColor;
            dark: IColor;
            white: IColor;
        };
        positive: {
            green: IColor;
        };
        negative: {
            red: IColor;
        };

        blue: string;
        green: string;
        dark: string;
        darkGreen: string;
        background: string;
        grey: string;
        lightGrey: string;
        lightestGrey: string;
    };
    font: {
        sizing: {
            primary: {
                level1: IFontSize;
                level2: IFontSize;
                level3: IFontSize;
                level4: IFontSize;
                level5: IFontSize; // body
                level6: IFontSize; // small
            };
        };
        type: {
            primary: string;
        };
    };
}

export type IWMWFontOptions = 'primary';

/* tslint:disable:object-literal-sort-keys */
const theme: IParcelTheme = {
    colors: {
        primary: {
            blue: { color: '#00B4E6', contrast: '#FFFFFF' },
            grey: { color: '#B4B4B4' },
            lightGrey: { color: '#f3f3f3' },
            darkGrey: { color: '#1b1b1b' },
            dark: { color: '#1F5766' },
            white: { color: '#ffffff' },
        },
        positive: {
            green: { color: '#32C771' },
        },
        negative: {
            red: { color: '#ff3333' },
        },

        blue: '#00B4E6',
        green: '#32C771',
        dark: '#333333',
        darkGreen: '#1F5766',
        background: '#F2F2F2',
        grey: '#BDBDBD',
        lightGrey: '#f3f3f3',
        lightestGrey: '#f9f9f9',
    },
    font: {
        sizing: {
            primary: {
                level1: {
                    mobile: pixelToRem(30),
                    desktop: pixelToRem(40),
                },
                level2: { mobile: pixelToRem(24), desktop: pixelToRem(30) },
                level3: { mobile: pixelToRem(20), desktop: pixelToRem(24) },
                level4: {
                    mobile: pixelToRem(18),
                    desktop: pixelToRem(20),
                },
                level5: { mobile: pixelToRem(16), desktop: pixelToRem(18) },
                level6: { mobile: pixelToRem(14), desktop: pixelToRem(14) },
            },
        },
        type: {
            primary: 'proxima-nova, sans-serif',
        },
    },
};

export default theme;
