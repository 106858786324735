import { groupBy } from 'lodash';

export function groupParcelsByDate(
    parcels: parcel.IParcel[]
): parcel.IGroupedParcel[] {
    const groupedParcels = groupBy(parcels, (parcel) =>
        parcel.timeOfArrival.toDate().toLocaleDateString([], {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        })
    );
    return Object.keys(groupedParcels).map((formattedDate) => {
        return {
            formattedDate,
            parcels: groupedParcels[formattedDate],
        };
    });
}
