import React, { useState } from 'react';
import { HeadingLevel1, PageWrapper, ActionButton } from '../ui-components';
import { ReactComponent as VisitorIcon } from '../assets/icons/visitor.svg';
import styled from '../styled-components';
import { SimpleModal } from '../components/ui/Modal/Modal.component';
import { SignInFlatVisitorForm } from '../components/SignInFlatVisitorForm/SignInFlatVisitorForm';
import { SignOutVisitorForm } from '../components/SignOutVisitorForm/SignOutVisitorForm';
import { SignInBuildingVisitorForm } from '../components/SignInBuildingVisitorForm/SignInBuildingVisitorForm';
import { Constrain } from '../components/ui';

const ButtonIcon = styled.div`
    text-align: right;
    margin-top: 3rem;
`;

const ActionButtonGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2.8rem;
`;

enum STATE_OPTIONS {
    SIGN_IN_FLAT_VISITOR,
    SIGN_IN_BUILDING_VISITOR,
    SIGN_OUT_VISITOR,
}

const FormHeading = styled(HeadingLevel1.withComponent('h3'))`
    margin-bottom: 3.5rem;
`;

const Index = () => {
    const [selectedState, setSelectecState] = useState<STATE_OPTIONS | null>(
        null
    );

    return (
        <>
            <PageWrapper data-testid="residentView">
                <Constrain>
                    <ActionButtonGrid>
                        <ActionButton
                            onClick={() =>
                                setSelectecState(
                                    STATE_OPTIONS.SIGN_IN_FLAT_VISITOR
                                )
                            }
                            data-testid="signInFlatVisitorButton"
                        >
                            I'm visiting a flat
                            <ButtonIcon>
                                <VisitorIcon />
                            </ButtonIcon>
                        </ActionButton>
                        <ActionButton
                            onClick={() =>
                                setSelectecState(
                                    STATE_OPTIONS.SIGN_IN_BUILDING_VISITOR
                                )
                            }
                            data-testid="signInBuildingVisitorButton"
                        >
                            I'm visiting the building
                            <ButtonIcon>
                                <VisitorIcon />
                            </ButtonIcon>
                        </ActionButton>
                        <ActionButton
                            onClick={() =>
                                setSelectecState(STATE_OPTIONS.SIGN_OUT_VISITOR)
                            }
                            data-testid="signOutVisitorButton"
                        >
                            I need to sign out
                            <ButtonIcon>
                                <VisitorIcon />
                            </ButtonIcon>
                        </ActionButton>
                    </ActionButtonGrid>
                </Constrain>
            </PageWrapper>

            <SimpleModal
                isOpen={selectedState !== null}
                handleModalClose={() => setSelectecState(null)}
            >
                {selectedState === STATE_OPTIONS.SIGN_IN_FLAT_VISITOR && (
                    <>
                        <FormHeading>Sign in to visit a flat</FormHeading>
                        <SignInFlatVisitorForm
                            onFinish={() => setSelectecState(null)}
                        />
                    </>
                )}

                {selectedState === STATE_OPTIONS.SIGN_IN_BUILDING_VISITOR && (
                    <>
                        <FormHeading>Sign in to visit the building</FormHeading>
                        <SignInBuildingVisitorForm
                            onFinish={() => setSelectecState(null)}
                        />
                    </>
                )}

                {selectedState === STATE_OPTIONS.SIGN_OUT_VISITOR && (
                    <>
                        <FormHeading style={{ marginBottom: '1.5rem' }}>
                            Sign out
                        </FormHeading>
                        <SignOutVisitorForm
                            onFinish={() => setSelectecState(null)}
                        />
                    </>
                )}
            </SimpleModal>
        </>
    );
};

export default Index;
