import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';
import { auth } from './firebase';

import Home from './routes/Home';
import LoginRoute from './routes/LoginRoute';
import AllResidences from './routes/Residences/AllResidences';
import NotificationOptOut from './routes/NotificationOptOut';
import { BuildingContext } from './context/BuildingContext';
import { getBuildingForUser } from './auth';
import styled from './styled-components';
import PasswordReset from './routes/PasswordReset';
import { setAppElement } from 'react-modal';
import Residences from './routes/Residences/Index';
import { Loading } from './components/ui';
import ConciergeIndex from './routes/Concierge';
import { Masthead } from './components/Masthead/Masthead.component';
import { PoweredByFooter } from './ui-components';

interface IAppState {
    loading: boolean;
    authenticated: boolean;
    user: firebase.User;
    building: parcel.IBuilding;
}

const Wrapper = styled.div`
    min-height: 100%;
`;

setAppElement('#app-element');

class App extends React.Component<{}, IAppState> {
    public state = {
        loading: true,
        authenticated: false,
        building: undefined as any,
        user: undefined as any,
    };

    public componentWillMount() {
        auth.onAuthStateChanged(async (user) => {
            const building = await getBuildingForUser(user);

            if (user && building) {
                this.setState({
                    authenticated: true,
                    loading: false,
                    user,
                    building,
                });
            } else {
                this.setState({
                    authenticated: false,
                    loading: false,
                    building: null as any,
                    user: null as any,
                });
            }
        });
    }

    public render() {
        return (
            <>
                {this.state.loading && <Loading />}
                {!this.state.loading && (
                    <Router>
                        <Wrapper>
                            {this.state.authenticated === true ? (
                                <BuildingContext.Provider
                                    value={{ ...this.state.building }}
                                >
                                    <Masthead />

                                    <Switch>
                                        <Route
                                            exact
                                            path="/"
                                            component={Home}
                                        />

                                        <Route
                                            exact
                                            path="/concierge"
                                            component={ConciergeIndex}
                                        />

                                        <Route
                                            exact
                                            path="/residences"
                                            component={AllResidences}
                                        />

                                        <Route
                                            path="/residences/:residenceId"
                                            component={Residences}
                                        />
                                    </Switch>

                                    <PoweredByFooter />
                                </BuildingContext.Provider>
                            ) : (
                                <Redirect to="/login" />
                            )}

                            <LoginRoute
                                exact
                                path="/login"
                                authenticated={this.state.authenticated}
                            />

                            <NotificationOptOut
                                exact
                                path="/notification-preferences/:emailAddress/building/:building/residence/:residence"
                            />

                            <PasswordReset exact path="/reset-password" />
                        </Wrapper>
                    </Router>
                )}
            </>
        );
    }
}

export default App;
