import { css } from '../styled-components';
import { ThemedCssFunction } from 'styled-components';
import { IParcelTheme } from '../theme';

/* tslint:disable:object-literal-sort-keys */
export const breakpoints: any = {
    s: 576,
    m: 768,
    l: 992,
    xl: 1200,
    xxl: 1600,
};

// Iterate through the sizes and create a media template
export const media: {
    [key in keyof typeof breakpoints]: ThemedCssFunction<IParcelTheme>
} = Object.keys(breakpoints as Array<keyof typeof breakpoints>).reduce(
    (acc: any, label: any) => {
        acc[label] = (first: any, ...args: any) => css`
            @media (min-width: ${breakpoints[label] / 16}em) {
                ${css(first, ...args)}
            }
        `;
        return acc;
    },
    {} as { [key in keyof typeof breakpoints]: ThemedCssFunction<IParcelTheme> }
);
