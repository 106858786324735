export function getRecipient(
    recipient: parcel.User | parcel.IResident | string
): string {
    if (typeof recipient === 'string') {
        return recipient;
    }

    if ('surname' in recipient) {
        return `${recipient.firstName} ${recipient.surname}`;
    }

    return `${recipient.firstName} ${recipient.lastName}`;
}
