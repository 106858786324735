import * as React from 'react';
import styled, { css } from '../../../styled-components';
import { pixelToRem } from '../../../utilities';

interface IConstrainProps {
    children: any;
    narrow?: boolean;
    relative?: boolean;
    className?: string;
}

export const CONSTRAIN_SIZING = {
    narrow: 860,
    normal: 1055,
};

export const Padding = css`
    padding: 0 ${pixelToRem(30)};
`;

const UnstyledConstrain: React.SFC<IConstrainProps> = ({
    className,
    children,
}) => <div className={className}>{children}</div>;

export const Constrain = styled(UnstyledConstrain)`
    width: 100%;
    max-width: ${({ narrow }) =>
        narrow
            ? pixelToRem(CONSTRAIN_SIZING.narrow)
            : pixelToRem(CONSTRAIN_SIZING.normal)};
    margin: 0 auto;
    position: ${({ relative }) => (relative ? 'relative' : 'auto')};

    ${Padding}
`;
