import styled from '../../../styled-components';

export const Well = styled.div`
    background-color: ${({ theme }) => theme.colors.primary.lightGrey.color};
    display: flex;
    align-content: middle;
    justify-content: center;
    padding: 2rem;
    border-radius: 3px;
`;
