import React from 'react';
import { ButtonLink } from '../../ui-components';
import { media, pixelToRem } from '../../utilities';
import styled from '../../styled-components';

interface ISelectAResidence {
    residences: parcel.IResidence[];
}

const Residences = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: ${pixelToRem(20)};

    li {
        margin-bottom: ${pixelToRem(20)};

        ${media.m`
            margin-bottom: 0;
        `}
    }

    ${ButtonLink} {
        display: block;
    }

    ${media.m`
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: ${pixelToRem(15)};
    `}
`;

export const SelectAResidence: React.FC<ISelectAResidence> = ({
    residences,
}) => {
    return (
        <Residences>
            {residences.map((residence: parcel.IResidence, index: number) => (
                <li key={index}>
                    <ButtonLink
                        to={`/residences/${residence.id}`}
                        data-testid={`select-residence-${residence.seqNo}`}
                    >
                        {residence.nameOrNumber}
                    </ButtonLink>
                </li>
            ))}
        </Residences>
    );
};
