import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { visitorsApi } from '../../api';
import { BuildingContext } from '../../context/BuildingContext';
import { TextInput, Button } from '../Form';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as UserCheckIcon } from '../../assets/icons/user-check.svg';
import * as Yup from 'yup';
import styled from '../../styled-components';
import { FormRow } from '../ui';
import { SuccessMessage } from '../../ui-components';

const INITIAL_VALUES = {
    name: '',
    company: '',
};

enum FORM_STATE {
    pending,
    suceeded,
}

interface SignInBuildingVisitorFormProps {
    onFinish: () => void;
}

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Please enter your full name')
        .max(300, 'Name is too long.')
        .required('Your full name is required'),
});

const Input = styled(TextInput)`
    font-size: 2rem;
`;

const FormActions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SuccessView = styled.div`
    ${SuccessMessage} {
        margin-bottom: 2rem;
    }

    .actions {
        display: flex;
        justify-content: space-between;
    }
`;

export const SignInBuildingVisitorForm: React.FC<SignInBuildingVisitorFormProps> = ({
    onFinish,
}) => {
    const building = useContext(BuildingContext);
    const [formState, setFormState] = useState<FORM_STATE>(FORM_STATE.pending);
    const [visitorName, setVisitorName] = useState<string>();

    return (
        <>
            {formState === FORM_STATE.pending && (
                <Formik
                    initialValues={INITIAL_VALUES}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            await visitorsApi.create(building.id, {
                                ...values,
                                visiting: 'Building',
                            });
                            setVisitorName(values.name);
                            setFormState(FORM_STATE.suceeded);
                        } catch (err) {
                            console.error(err);
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            data-testid="SignInBuildingVisitorForm"
                        >
                            <FormRow>
                                <Input
                                    label="Full name"
                                    id="name"
                                    name="name"
                                    value={values.name}
                                    placeholder="Enter your full name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        (touched.name && errors.name) ||
                                        undefined
                                    }
                                />
                            </FormRow>

                            <FormRow>
                                <Input
                                    label="Company"
                                    id="company"
                                    name="company"
                                    value={values.company}
                                    placeholder="Enter your company name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormRow>

                            <FormActions>
                                <Button
                                    type="submit"
                                    data-testid="signInVisitor"
                                >
                                    Sign in <ArrowRightIcon />
                                </Button>
                            </FormActions>
                        </form>
                    )}
                </Formik>
            )}

            {formState === FORM_STATE.suceeded && (
                <SuccessView>
                    <SuccessMessage>
                        Thanks {visitorName}, you’re signed in. Don’t forget to
                        sign out when leaving
                        <UserCheckIcon />
                    </SuccessMessage>

                    <div className="actions">
                        <Button
                            type="button"
                            onClick={() => {
                                setFormState(FORM_STATE.pending);
                                setVisitorName(undefined);
                            }}
                            data-testid="signInAnotherVisitor"
                        >
                            Sign in another visitor <ArrowRightIcon />
                        </Button>

                        <Button
                            type="button"
                            onClick={() => onFinish()}
                            data-testid="finishedSignIn"
                        >
                            Finished <ArrowRightIcon />
                        </Button>
                    </div>
                </SuccessView>
            )}
        </>
    );
};
