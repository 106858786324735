import { BaseApi } from './base-api';
import 'firebase/firestore';

export interface IUserApiResource {
    getUsersInResidence(residenceId: string): Promise<parcel.User[]>;
    getByEmail(email: string): Promise<parcel.User>;
    updateEmailNotification(
        user: parcel.User,
        settingValue: boolean
    ): Promise<void>;
}

class UsersApi extends BaseApi implements IUserApiResource {
    public async getUsersInResidence(
        residenceId: string
    ): Promise<parcel.User[]> {
        let users: parcel.User[] = [];
        const dataSnapshot = await this.db
            .collection('users')
            .where('residenceId', '==', residenceId)
            .get();

        dataSnapshot.forEach(
            (snapshot) => (users = [...users, snapshot.data() as parcel.User])
        );

        return users;
    }

    public async getByEmail(email: string): Promise<parcel.User> {
        const dataSnapshot = await this.db
            .collection('users')
            .where('email', '==', email)
            .get();

        if (dataSnapshot.size !== 1) {
            throw new Error(`Did not find user with email: ${email}`);
        }

        return dataSnapshot.docs[0].data() as parcel.User;
    }

    public async updateEmailNotification(
        user: parcel.User,
        settingValue: boolean
    ): Promise<void> {
        const update: Partial<parcel.User> = {
            settings: {
                emailNotifications: settingValue,
            },
        };
        await this.db
            .collection('users')
            .doc(user.id)
            .set(update, { merge: true });
    }
}

export const usersApi = new UsersApi();
