import styled, { css } from '../../../styled-components';
import arrowDown from '../../../assets/images/arrow-down.svg';
import { pixelToRem } from '../../../utilities';
import { IInputOptions } from '../';

const FocusedOrValue = css`
    border-color: ${({ theme }) => theme.colors.primary.blue.color};
    outline: none;
`;

export const Select = styled.select<IInputOptions>`
    appearance: none;
    border: 0;
    padding: ${pixelToRem(3)};
    background: url(${arrowDown}) no-repeat;
    background-position: 98% center;
    border-bottom: solid 1px ${({ theme }) => theme.colors.primary.grey.color};
    border-radius: 0;

    ${({ hasValue }) => (hasValue ? FocusedOrValue : '')}
`;
