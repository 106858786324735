import React from 'react';
import styled from '../../styled-components';
import OurBlockLogo from '../../assets/images/our-block-logo.svg';

export const PoweredByFooter = styled(({ className }) => (
    <footer className={className}>
        <span>Powered by</span>
        <img src={OurBlockLogo} alt="OurBlock logo" />
    </footer>
))`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    img {
        width: 100px;
        margin-left: 0.5rem;
    }
`;
