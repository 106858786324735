import React, { useState, useContext, useEffect } from 'react';
import { BuildingContext } from '../../context/BuildingContext';
import { RouteComponentProps } from 'react-router-dom';
import styled from '../../styled-components';
import { Modal } from '../../components/ui/Modal/Modal.component';
import CollectParcelForm from '../../components/CollectParcelForm/CollectParcelForm.container';
import { HeadingLevel4 } from '../../ui-components';
import { ReactComponent as ParcelSVG } from '../../assets/icons/parcel.svg';
import { ReactComponent as CheckSVG } from '../../assets/icons/check.svg';
import { ReactComponent as LockSVG } from '../../assets/icons/lock.svg';

import { Button } from '../../components/Form';
import { parcelsApi } from '../../api';
import { getRecipient } from '../../components/ViewRecentParcels/getRecipient';

const getRecipientName = (recipient: string | parcel.User): string => {
    return typeof recipient === 'string'
        ? recipient
        : `${recipient.firstName} ${recipient.lastName}`;
};

const ParcelListItem = styled.li`
    margin-bottom: 0.4rem;
`;

const ParcelList = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
    list-style: none;
    padding: 0;
    margin: 2rem 0;

    ${ParcelListItem}:last-child {
        margin-bottom: 0;
    }
`;

const Parcel = styled.ul<{ isSelected?: boolean }>`
    background: white;
    border-radius: 3px;
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
        padding: 1.5rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:first-child {
            font-weight: 600;
        }

        &:nth-child(even) {
            background: ${({ theme }) => theme.colors.lightestGrey};
        }
    }

    .parcel-svg {
        width: 3.4rem;
        height: 3.4rem;
        fill: ${({ theme }) => theme.colors.grey};
    }

    .check-svg {
        fill: ${({ theme }) => theme.colors.green};
    }

    a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.darkGreen};
    }

    ${({ isSelected, theme }) =>
        isSelected &&
        `
        ${Button} {
            background: ${theme.colors.green};
            color: white;
        }
    `}
`;

const Section = styled.section`
    margin-bottom: 2rem;

    ${Button} {
        margin-left: auto;
    }
`;

const NoParcelsMessage = styled.div`
    background-color: white;
    padding: 2rem;
    text-align: center;
    border-radius: 3px;
    margin: 2rem 0 0;
`;

const DigitalSign = styled.span`
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
`;

const LoadingMessage = NoParcelsMessage;

function removeParcelsFromArray(
    parcelArray: parcel.IUncollectedParcel[],
    parcels: parcel.IUncollectedParcel[]
): parcel.IUncollectedParcel[] {
    const array = [...parcelArray];
    parcels.forEach((parcel) => {
        const index = array.findIndex((p) => p.id === parcel.id);
        array.splice(index, 1);
    });
    return array;
}

export const ParcelsTab = ({
    match,
}: RouteComponentProps<{ residenceId: string }>) => {
    const building = useContext(BuildingContext);
    const [hasLoadedUncollectedParcels, setHasLoadedUncollectedParcels] =
        useState<boolean>(false);
    const [isCollecting, setIsCollecting] = useState<boolean>(false);
    const [uncollectedParcelsForResidence, setUncollectedParcelsForResidence] =
        useState<parcel.IUncollectedParcel[]>([]);
    const [collectedParcelsForResidence, setCollectedParcelsForResidence] =
        useState<parcel.IParcel[]>([]);
    const [selectedParcels, setSelectedParcels] = useState<
        parcel.IUncollectedParcel[]
    >([]);

    useEffect(() => {
        const unsubscribe = parcelsApi
            .getAllUncollected(building.id, {
                where: [['residenceId', '==', match.params.residenceId]],
                orderBy: [{ key: 'timeOfArrival', order: 'desc' }],
            })
            .subscribe((uncollectedParcels) => {
                let newUncollectedParcelsForResidence: parcel.IUncollectedParcel[] =
                    [];

                uncollectedParcels.forEach((uncollectedParcel) =>
                    newUncollectedParcelsForResidence.push(uncollectedParcel)
                );

                setUncollectedParcelsForResidence(
                    newUncollectedParcelsForResidence
                );
                setHasLoadedUncollectedParcels(true);
            });

        return () => unsubscribe.unsubscribe();
    }, [building.id, match.params.residenceId]);

    useEffect(() => {
        const unsubscribe = parcelsApi
            .getAll(building.id, {
                where: [
                    ['residenceId', '==', match.params.residenceId],
                    ['timeOfCollection', '>', new Date('2017-01-01')],
                ],
                orderBy: [
                    { key: 'timeOfCollection', order: 'desc' },
                    { key: 'timeOfArrival', order: 'desc' },
                ],
            })
            .subscribe((parcels) => {
                let newCollectedParcelsForResidence: parcel.IParcel[] = [];

                // waiting so that the parcels can be filtered correctly without causing an error
                if (hasLoadedUncollectedParcels) {
                    parcels.forEach((parcel) => {
                        if (
                            !uncollectedParcelsForResidence.some(
                                (uncollectedParcel) =>
                                    uncollectedParcel.parcelId === parcel.id
                            )
                        ) {
                            newCollectedParcelsForResidence.push(parcel);
                        }
                    });
                }

                setCollectedParcelsForResidence(
                    newCollectedParcelsForResidence
                );
            });

        return () => unsubscribe.unsubscribe();
    }, [
        building.id,
        match.params.residenceId,
        uncollectedParcelsForResidence,
        hasLoadedUncollectedParcels,
    ]);

    const toggleParcelSelection = (parcel: parcel.IUncollectedParcel) => {
        const parcelIndex = selectedParcels.findIndex(
            (sp) => sp.id === parcel.id
        );

        if (parcelIndex >= 0) {
            const selectedParcelsCopy = [...selectedParcels];
            selectedParcelsCopy.splice(parcelIndex, 1);
            setSelectedParcels(selectedParcelsCopy);
            return;
        }

        setSelectedParcels([...selectedParcels, parcel]);
    };

    const removeParcelsThatHaveBeenCollected = () => {
        const remainingParcels = removeParcelsFromArray(
            uncollectedParcelsForResidence,
            selectedParcels
        );
        setUncollectedParcelsForResidence(remainingParcels);
        setIsCollecting(false);
    };

    return (
        <>
            <Section>
                <HeadingLevel4>Parcels ready for collection</HeadingLevel4>

                {hasLoadedUncollectedParcels &&
                    uncollectedParcelsForResidence.length === 0 && (
                        <NoParcelsMessage>
                            There are no parcels waiting for collection
                        </NoParcelsMessage>
                    )}

                {!hasLoadedUncollectedParcels && (
                    <LoadingMessage>Loading parcels...</LoadingMessage>
                )}

                <ParcelList>
                    {uncollectedParcelsForResidence.length > 0 &&
                        uncollectedParcelsForResidence.map((parcel, i) => {
                            const recipientName = getRecipientName(
                                parcel.recipient
                            );
                            return (
                                <ParcelListItem
                                    key={i}
                                    data-testid={`parcel-${recipientName.replace(
                                        ' ',
                                        ''
                                    )}`}
                                >
                                    <Parcel
                                        onClick={() =>
                                            toggleParcelSelection(parcel)
                                        }
                                        isSelected={selectedParcels.some(
                                            (sp) => sp.id === parcel.id
                                        )}
                                        data-testid="uncollectedParcel"
                                    >
                                        <li>
                                            <ParcelSVG className="parcel-svg" />

                                            <Button small>
                                                {(selectedParcels.some(
                                                    (sp) => sp.id === parcel.id
                                                ) &&
                                                    'Selected') ||
                                                    'Select'}
                                            </Button>
                                        </li>
                                        <li>
                                            <span>To:</span>
                                            <span>{recipientName}</span>
                                        </li>
                                        <li>
                                            <span>Qty:</span>
                                            <span>{parcel.quantity}</span>
                                        </li>
                                        <li>
                                            <span>Arrived:</span>
                                            <span>
                                                {parcel.timeOfArrival.toDate &&
                                                    parcel.timeOfArrival
                                                        .toDate()
                                                        .toLocaleDateString(
                                                            [],
                                                            {
                                                                weekday:
                                                                    'short',
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: '2-digit',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                            }
                                                        )}
                                            </span>
                                        </li>
                                    </Parcel>
                                </ParcelListItem>
                            );
                        })}
                </ParcelList>

                {uncollectedParcelsForResidence.length > 0 && (
                    <Button
                        onClick={() => setIsCollecting(true)}
                        disabled={selectedParcels.length === 0}
                        style={{ alignSelf: 'flex-end' }}
                        data-testid="signForParcels"
                    >
                        Sign for parcels
                    </Button>
                )}
            </Section>

            <Section>
                <HeadingLevel4>Collected parcels</HeadingLevel4>

                {collectedParcelsForResidence.length === 0 && (
                    <NoParcelsMessage>
                        No parcels have been collected
                    </NoParcelsMessage>
                )}

                <ParcelList>
                    {collectedParcelsForResidence.map((parcel, i) => (
                        <ParcelListItem
                            key={i}
                            data-testid={`collectedParcel-${parcel.id}`}
                        >
                            <Parcel data-testid="parcel">
                                <li>
                                    <ParcelSVG className="parcel-svg" />

                                    <CheckSVG className="check-svg" />
                                </li>
                                <li>
                                    <span>To:</span>
                                    <span>
                                        {getRecipient(parcel.recipient)}
                                    </span>
                                </li>
                                <li>
                                    <span>Qty:</span>
                                    <span>{parcel.quantity}</span>
                                </li>
                                <li>
                                    <span>Arrived:</span>
                                    <span>
                                        {parcel.timeOfArrival.toDate &&
                                            parcel.timeOfArrival
                                                .toDate()
                                                .toLocaleDateString([], {
                                                    weekday: 'short',
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                    </span>
                                </li>
                                <li>
                                    <span>Collected:</span>
                                    <span>
                                        {parcel.timeOfCollection &&
                                            parcel.timeOfCollection
                                                .toDate()
                                                .toLocaleDateString([], {
                                                    weekday: 'short',
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                    </span>
                                </li>
                                <li>
                                    <span>Signature</span>
                                    <span>
                                        {parcel.signature &&
                                            Array.isArray(parcel.signature) && (
                                                <a
                                                    href={parcel.signature[0]}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    View
                                                </a>
                                            )}
                                        {parcel.signature &&
                                            !Array.isArray(
                                                parcel.signature
                                            ) && (
                                                <DigitalSign>
                                                    Digital <LockSVG />
                                                </DigitalSign>
                                            )}
                                    </span>
                                </li>
                            </Parcel>
                        </ParcelListItem>
                    ))}
                </ParcelList>
            </Section>

            <Modal
                isOpen={isCollecting}
                handleModalClose={() => setIsCollecting(false)}
                fullWidth
            >
                <CollectParcelForm
                    parcelsToBeCollected={selectedParcels}
                    onSuccess={() => removeParcelsThatHaveBeenCollected()}
                />
            </Modal>
        </>
    );
};
