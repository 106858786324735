import React, { useContext } from 'react';
import { CollectParcelForm } from './CollectParcelForm.component';
import { parcelsApi } from '../../api';
import { BuildingContext } from '../../context/BuildingContext';

interface ICollectParcelFormContainerProps {
    parcelsToBeCollected: parcel.IUncollectedParcel[];
    onSuccess: () => void;
}

export interface ICollectParcelsSubmitData {
    signatureData: string;
}

const CollectParcelFormContainer: React.SFC<ICollectParcelFormContainerProps> = ({
    parcelsToBeCollected,
    onSuccess,
}) => {
    const building = useContext(BuildingContext);

    const submitCollection = async (
        parcelCollectionData: ICollectParcelsSubmitData
    ): Promise<any> => {
        try {
            const response = await Promise.all(
                parcelsToBeCollected.map((parcel) =>
                    parcelsApi.collect(
                        parcel,
                        building.id,
                        parcelCollectionData.signatureData
                    )
                )
            );
            onSuccess();

            return response;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    return <CollectParcelForm submitCollection={submitCollection} />;
};

export default CollectParcelFormContainer;
