import styled from '../../../styled-components';
import { media, pixelToRem } from '../../../utilities';
import { Input, Select } from '../';

export const FormRow = styled.div`
    margin-bottom: ${pixelToRem(25)};

    ${media.m`
        display: flex;
        width: 100%;
    `}
`;

export const FormRowItem = styled.div`
    margin-bottom: ${pixelToRem(10)};
    display: block;
    position: relative;

    label {
        display: block;
    }

    ${Input}, ${Select} {
        display: block;
        width: 100%;
    }

    ${media.m`
        flex: 1;

        margin: 0 ${pixelToRem(20)};

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    `}
`;
