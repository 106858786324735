import React from 'react';
import { Constrain } from '../../components/ui';
import SelectAResidence from '../../components/Parcels/SelectAResidence.container';
import { PageWrapper, HeadingLevel2 } from '../../ui-components';
import { ReactComponent as ResidencesSVG } from '../../assets/icons/residences.svg';
import styled from '../../styled-components';

const ConstrainContainer = styled(Constrain)`
    .heading {
        display: flex;
        margin-bottom: 2rem;

        svg {
            margin-right: 1rem;
        }
    }
`;

const AllResidences = () => {
    return (
        <PageWrapper>
            <ConstrainContainer>
                <HeadingLevel2
                    bold
                    className="heading"
                    data-testid="parcelsHeader"
                >
                    <ResidencesSVG />
                    Residences
                </HeadingLevel2>

                <SelectAResidence />
            </ConstrainContainer>
        </PageWrapper>
    );
};
export default AllResidences;
