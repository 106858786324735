import React from 'react';
import { RouteChildrenProps, Route, RouteProps } from 'react-router';
import NotificationOptOutForm from '../components/NotificationOptOutForm/NotificationOptOutForm.component';

interface IParams {
    building: string;
    residence: string;
    emailAddress: string;
}

const NotificationOptOut = (routeProps: RouteProps) => {
    return (
        <Route
            {...routeProps}
            render={(props: RouteChildrenProps<IParams>) => {
                if (!props.match) {
                    throw new Error('Cannot load route: Missing params');
                }
                const {
                    building,
                    residence,
                    emailAddress,
                } = props.match.params;

                return (
                    <NotificationOptOutForm
                        buildingId={building}
                        residenceId={residence}
                        emailAddress={emailAddress}
                    />
                );
            }}
        />
    );
};

export default NotificationOptOut;
