import React from 'react';
import Downshift from 'downshift';
import styled from '../../styled-components';
import { StylelessButton } from '../ui';
import { pixelToRem } from '../../utilities';

export interface ISearchSelectItem {
    label: string;
    value: string | number;
}

export interface ISearchSelectProps {
    placeholder: string;
    items: ISearchSelectItem[];
    value: ISearchSelectItem | null;
    handleSelect: (value: ISearchSelectItem) => void;
    handleAddSelect?: (value: string) => void;
    label: string;
}

const DropDownArrow = () => (
    <svg width="15" height="9" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M.22.274a.797.797 0 0 0 0 1.099l6.197 6.422a1.462 1.462 0 0 0 2.12 0l6.244-6.47c.289-.3.293-.785.007-1.09A.732.732 0 0 0 13.72.228l-5.713 5.92a.731.731 0 0 1-1.06 0L1.279.274a.73.73 0 0 0-1.06 0"
            fill="#4A4A4A"
            fillRule="evenodd"
        />
    </svg>
);

const SearchSelectWrapper = styled.div`
    position: relative;
`;

const SelectInput = styled.div<{ hasSelection: boolean }>`
    width: 100%;
    display: flex;
    border-bottom: solid 1px
        ${({ theme, hasSelection }) =>
            hasSelection
                ? theme.colors.primary.blue.color
                : theme.colors.primary.grey.color};

    input {
        flex: 1 0 auto;
        border: 0;
        margin: 0;
        padding: ${pixelToRem(8)} ${pixelToRem(4)};

        &:focus {
            outline: none;
        }
    }
`;

const Option = styled.li`
    padding: ${pixelToRem(8)} ${pixelToRem(4)};

    &:hover {
        background-color: ${({ theme }) =>
            theme.colors.primary.lightGrey.color};
        cursor: pointer;
    }
`;

const SelectOptions = styled.ul`
    position: absolute;
    z-index: 1000;
    width: 100%;
    margin: 0;
    top: calc(100% + 0.5rem);
    padding: 0;
    background: white;
    list-style: none;
    box-shadow: ${() => ` 0 2px 4px 0 rgba(102, 102, 102, 0.5);`};
    overflow: scroll;
    max-height: 277px;
    color: ${({ theme }) => theme.colors.primary.dark.color};
    border-radius: 3px;
`;

const AddButton = styled(StylelessButton)`
    padding: ${pixelToRem(8)} ${pixelToRem(4)};
    display: block;
    text-decoration: underline;
    background-color: #00b4e63d;
    width: 100%;
    text-align: left;
`;

const DropDownButton = styled(StylelessButton)`
    padding: 0 4px;
`;

export const SearchSelect: React.FC<ISearchSelectProps> = ({
    items,
    label,
    value,
    placeholder,
    handleSelect,
    handleAddSelect,
}) => {
    const handleAddSelection = (
        e: React.SyntheticEvent<HTMLButtonElement>,
        input: string
    ) => {
        e.preventDefault();
        handleAddSelect && handleAddSelect(input);
    };

    return (
        <SearchSelectWrapper>
            <Downshift
                onChange={selection => handleSelect(selection || null)}
                itemToString={item => (item ? item.label : '')}
                selectedItem={value}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getLabelProps,
                    getMenuProps,
                    getToggleButtonProps,
                    isOpen,
                    openMenu,
                    inputValue,
                    selectedItem,
                    clearSelection,
                }) => {
                    const filteredItems = items.filter(
                        item =>
                            !inputValue ||
                            item.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                    );

                    return (
                        <div>
                            <label {...getLabelProps()}>{label}</label>
                            <SelectInput hasSelection={value !== null}>
                                <input
                                    {...getInputProps({
                                        placeholder,
                                        onChange: e => {
                                            if (e.target.value === '') {
                                                clearSelection();
                                            }
                                        },
                                    })}
                                    onClick={() => {
                                        openMenu();
                                    }}
                                />
                                <DropDownButton {...getToggleButtonProps()}>
                                    <DropDownArrow />
                                </DropDownButton>
                            </SelectInput>
                            {isOpen && (
                                <>
                                    <SelectOptions {...getMenuProps()}>
                                        {handleAddSelect &&
                                            inputValue &&
                                            filteredItems.length === 0 && (
                                                <AddButton
                                                    type="button"
                                                    onClick={e =>
                                                        handleAddSelection(
                                                            e,
                                                            inputValue
                                                        )
                                                    }
                                                >
                                                    Manually add '{inputValue}'
                                                </AddButton>
                                            )}
                                        {filteredItems.map((item, index) => (
                                            <Option
                                                {...getItemProps({
                                                    key: item.value,
                                                    index,
                                                    item,
                                                    style: {
                                                        fontWeight:
                                                            selectedItem ===
                                                            item
                                                                ? 'bold'
                                                                : 'normal',
                                                    },
                                                })}
                                            >
                                                {item.label}
                                            </Option>
                                        ))}
                                    </SelectOptions>
                                </>
                            )}
                        </div>
                    );
                }}
            </Downshift>
        </SearchSelectWrapper>
    );
};
