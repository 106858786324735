import React from 'react';
import styled from '../../styled-components';

const UnstyledMessage: React.FC<parcel.IBaseStyledComponent> = ({
    className,
    children,
    ...props
}) => (
    <div className={className} data-testid="message" {...props}>
        {children}
    </div>
);

export const InfoMessage = styled(UnstyledMessage)`
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.primary.blue.color};
    color: white;
    padding: 1rem 1.6rem;
    display: flex;
    align-items: flex-start;
    font-size: 1.8rem;
    line-height: 1.3;
    min-height: 60px;
    flex-direction: column;
    margin-bottom: 2rem;
    font-weight: 600;

    svg {
        flex: 0 0 auto;
        margin-left: 2rem;
        align-self: flex-end;
    }
`;
