import styled from '../../styled-components';

export const ActionButton = styled.button`
    border-radius: 3px;
    border: solid 1px ${({ theme }) => theme.colors.darkGreen};
    padding: 1.7rem;
    background: white;
    text-align: left;
    font-size: 2.2rem;
    line-height: 1.3;
    display: inline-flex;
    min-height: 150px;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    div {
        align-self: flex-end;
    }
`;
