import React from 'react';
import { Constrain } from '../components/ui';
import AddNewParcel from '../components/AddNewParcel/AddNewParcel.container';
import ViewRecentParcels from '../components/ViewRecentParcels/ViewRecentParcels.container';
import { PageWrapper } from '../ui-components';

const ConciergeIndex = () => (
    <PageWrapper data-testid="conciergeVew">
        <Constrain>
            <AddNewParcel />

            <ViewRecentParcels />
        </Constrain>
    </PageWrapper>
);

export default ConciergeIndex;
