import React, { useState } from 'react';
import styled from '../../styled-components';
import { FormRow, FormRowItem } from '../ui';
import { pixelToRem, media } from '../../utilities';
import logo from '../../assets/images/OurBlock-logo.svg';
import { TextInput, Button, FormActions } from '../Form';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { Link } from '../../ui-components';

interface ILoginFormProps extends parcel.IBaseStyledComponent {
    login: (username: string, password: string) => void;
}

export const LoginHeader = styled.div`
    text-align: center;
    padding: 0 6rem;
    margin-bottom: 2rem;
`;

const ErrorWrapper = styled.div`
    position: relative;
    height: ${pixelToRem(40)};
`;

const Error = styled.p`
    position: absolute;
    color: ${({ theme }) => theme.colors.negative.red.color};
    font-size: ${({ theme }) => theme.font.sizing.primary.level5.mobile};
    left: 0;
    right: 0;
    text-align: center;
    margin-bottom: 0;

    ${media.m`
        font-size: ${({ theme }) => theme.font.sizing.primary.level5.desktop};
    `}
`;

const ResetPasswordWrapper = styled.div`
    margin-bottom: 3rem;
`;

const UnstyledLoginForm: React.SFC<ILoginFormProps> = ({
    className,
    login,
}) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    async function submit(e: React.SyntheticEvent<HTMLFormElement>) {
        e.preventDefault();

        if (email.length === 0 || password.length === 0) {
            return;
        }

        try {
            await login(email, password);
        } catch (err) {
            setError(true);
        }
    }

    return (
        <form className={className} onSubmit={submit}>
            <LoginHeader>
                <img src={logo} alt="OurBlock logo" />
            </LoginHeader>
            <ErrorWrapper>
                {error && <Error>Username or password not recognised.</Error>}
            </ErrorWrapper>
            <FormRow>
                <FormRowItem>
                    <TextInput
                        name="email"
                        type="email"
                        label="Email:"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => {
                            setError(false);
                            setEmail(e.target.value);
                        }}
                    />
                </FormRowItem>
            </FormRow>
            <FormRow>
                <FormRowItem>
                    <TextInput
                        name="password"
                        type="password"
                        label="Password:"
                        value={password}
                        onChange={(e) => {
                            setError(false);
                            setPassword(e.target.value);
                        }}
                    />
                </FormRowItem>
            </FormRow>

            <ResetPasswordWrapper>
                <Link to="/reset-password">Forgot your password?</Link>
            </ResetPasswordWrapper>

            <FormActions>
                <Button type="submit">
                    Sign in <ArrowRightIcon />
                </Button>
            </FormActions>
        </form>
    );
};

export const LoginForm = styled(UnstyledLoginForm)`
    width: 100%;
    max-width: ${pixelToRem(400)};
    padding: ${pixelToRem(30)};
    border: solid 1px ${({ theme }) => theme.colors.primary.blue.color};
    border-radius: 5px;
    background: white;
    font-size: 1.6rem;
`;
