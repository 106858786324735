import styled, { css } from '../../../styled-components';
import { pixelToRem, media } from '../../../utilities';
import theme from '../../../theme';

export type ButtonType = 'primary' | 'danger';

interface IButtonOptions {
    fullWidth?: boolean;
    buttonType?: ButtonType;
}

export const StylelessButton = styled.button`
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    background: none;

    &:hover {
        cursor: pointer;
    }
`;

export const ButtonStyles = css`
    border: solid 1px ${({ theme }) => theme.colors.primary.blue.color};
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.primary.blue.color};
    padding: ${pixelToRem(10)} ${pixelToRem(20)};
    font-size: ${({ theme }) => theme.font.sizing.primary.level5.mobile};
    transition: all 200ms;
    color: white;
    font-weight: bold;

    ${media.m`
        font-size: ${({ theme }) => theme.font.sizing.primary.level5.desktop};
    `}

    &:hover {
        background: ${({ theme }) => theme.colors.primary.blue.contrast};
        color: ${({ theme }) => theme.colors.primary.blue.color};
        cursor: pointer;
    }

    &[disabled] {
        opacity: 0.3;
        background: ${({ theme }) => theme.colors.primary.blue.color};
    }
`;

function getStyledFromType(buttonType: ButtonType) {
    switch (buttonType) {
        case 'primary':
            return css`
                background: ${theme.colors.primary.blue.color};
                color: ${theme.colors.primary.blue.contrast};
            `;
        case 'danger':
            return css`
                background: ${theme.colors.negative.red.color};
                color: ${theme.colors.primary.white.color};
                border-color: ${theme.colors.negative.red.color};
            `;
    }
}

export const Button = styled.button<IButtonOptions>`
    ${ButtonStyles}

    ${({ buttonType }: IButtonOptions) =>
        buttonType && getStyledFromType(buttonType)}
`;

export const SecondaryButton = styled(Button)`
    background: ${({ theme }) => theme.colors.primary.blue.contrast};
    border: solid 1px ${({ theme }) => theme.colors.primary.blue.color};
    color: ${({ theme }) => theme.colors.primary.blue.color};
`;

export const LinkButton = styled(StylelessButton)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary.blue.color};

    &:hover {
        text-decoration: underline;
    }
`;
