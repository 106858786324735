import React from 'react';
import styled from '../../../styled-components';
import loadingGif from '../../../assets/images/loading.gif';

const LoadingWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const LoadingImg = styled.img`
    width: 30px;
    height: 30px;
`;

export const Loading = () => (
    <LoadingWrapper>
        <LoadingImg src={loadingGif} alt="Loading animation" />
    </LoadingWrapper>
);
