import React, { useState, useContext } from 'react';
import { BuildingContext } from '../../context/BuildingContext';
import { ViewRecentParcels } from './ViewRecentParcels.component';
import useAsyncEffect from 'use-async-effect';
import { parcelsApi } from '../../api';
import { groupParcelsByDate } from './groupParcelsByDate';

const ViewRecentParcelsContainer = () => {
    const building = useContext(BuildingContext);
    const [parcels, setParcels] = useState<parcel.IGroupedParcel[]>([]);

    useAsyncEffect(() => {
        parcelsApi
            .getAll(building.id, {
                orderBy: [{ key: 'timeOfArrival', order: 'desc' }],
            })
            .subscribe((parcels) => {
                const parcelsByDate = groupParcelsByDate(parcels);
                setParcels(parcelsByDate);
            });
    }, [building.id]);

    return <ViewRecentParcels groupedParcels={parcels} />;
};

export default ViewRecentParcelsContainer;
