import React from 'react';
import { residencesApi, usersApi } from '../../api';
import styled from 'styled-components';
import { pixelToRem } from '../../utilities';
import logo from '../../assets/images/OurBlock-logo.svg';

enum STATUS {
    pristine,
    success,
    failure,
}

interface INotificationOptOurFormProps extends parcel.IBaseStyledComponent {
    emailAddress: string;
    residenceId: string;
    buildingId: string;
}

interface INotificationOptOutFormState {
    status: STATUS;
}

const Logo = styled.img`
    margin: 3rem auto;
    max-width: ${pixelToRem(200)};
    display: block;
`;

const Message = styled.p`
    max-width: ${pixelToRem(500)};
    margin: 0 auto;
    background: ${({ theme }) => theme.colors.primary.lightGrey.color};
    border-radius: 2px;
    padding: 2rem;
`;

class NotificationOptOutForm extends React.Component<
    INotificationOptOurFormProps,
    INotificationOptOutFormState
> {
    public state = {
        status: STATUS.pristine,
    };

    public async componentDidMount() {
        const residences = await residencesApi.get(this.props.buildingId);
        const user = await usersApi.getByEmail(this.props.residenceId);

        const residence: parcel.IResidence = {
            ...(residences.find(
                (r) => r.id === this.props.residenceId
            ) as parcel.IResidence),
        };

        if (!residence) {
            return this.setState({
                status: STATUS.failure,
            });
        }

        try {
            await usersApi.updateEmailNotification(user, false);

            this.setState({ status: STATUS.success });
        } catch (err) {
            console.error(err);
            this.setState({ status: STATUS.failure });
        }
    }

    public render() {
        return (
            <div className={this.props.className}>
                <Logo src={logo} alt="OurBlock logo" />

                <Message>{this.getMessage()}</Message>
            </div>
        );
    }

    private getMessage() {
        switch (this.state.status) {
            case STATUS.success:
                return (
                    <span>
                        <strong>
                            Succefully unsubscribed from email notifications
                        </strong>
                        , if you'd like to re-enable these notifications let us
                        know by getting in contact via{' '}
                        <a href="mailto:hello@ourblock.app">
                            hello@ourblock.app
                        </a>
                    </span>
                );
            case STATUS.failure:
                return (
                    <span>
                        It looks like something unsual happened, we've been
                        notified that this occured but in the meantime contact
                        us via{' '}
                        <a href="mailto:hello@ourblock.app">
                            hello@ourblock.app
                        </a>{' '}
                        and we can disable these notifications for you.
                    </span>
                );
            default:
                return <span>Updating your preferences...</span>;
        }
    }
}

export default styled(NotificationOptOutForm)``;
