import styled, { css } from '../../../styled-components';
import { pixelToRem } from '../../../utilities';

export interface IInputOptions {
    hasValue: boolean;
}

const FocusedOrValue = css`
    border-color: ${({ theme }) => theme.colors.primary.blue.color};
    outline: none;
`;

export const Input = styled.input<IInputOptions>`
    border: 0;
    padding: ${pixelToRem(8)} ${pixelToRem(4)};
    border-bottom: solid 1px ${({ theme }) => theme.colors.primary.grey.color};

    &:focus {
        ${FocusedOrValue}
    }

    ${({ hasValue }) => (hasValue ? FocusedOrValue : '')}
`;
