import React from 'react';
import Downshift from 'downshift';
import { ReactComponent as DropDownIcon } from '../../assets/icons/arrow-down.svg';
import styled from '../../styled-components';
import { InfoMessage } from '../../ui-components';

export interface SearchableSelectItem {
    label: string;
    value: string;
}

interface SearchableSelectProps {
    options: SearchableSelectItem[];
    label: string;
    placeholder: string;
    id: string;
    handleSelect: (value: SearchableSelectItem) => void;
    error?: string;
}

const Container = styled.div`
    display: block;
    width: 100%;
    position: relative;
`;

const Control = styled.div<{ hasItemSelected: boolean; error: boolean }>`
    position: relative;

    label {
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.darkGreen};
        font-size: 1.6rem;
        font-weight: 600;
        display: block;
        margin-bottom: 1rem;
    }

    input {
        font-size: 2rem;
        padding: 0.5rem 0;
        border: 0;
        border-bottom: solid 1px
            ${({ theme, hasItemSelected, error }) =>
                error
                    ? theme.colors.negative.red.color
                    : hasItemSelected
                    ? theme.colors.primary.blue.color
                    : theme.colors.darkGreen};
        width: 100%;
        transition: border-color 200ms;

        &:focus {
            border-color: ${({ theme }) => theme.colors.primary.blue.color};
            outline: none;
        }
    }

    button {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0.7rem 0 0.7rem 0.7rem;
        appearance: none;
        margin: 0;
        background: none;
        border: 0;
    }
`;

const Option = styled.li<{ isSelected: boolean }>`
    padding: 0.9rem 1.3rem;
    background: ${({ isSelected }) =>
        isSelected ? 'rgba(0, 180, 230, .25)' : 'white'};

    small {
        font-size: 1rem;
        display: block;
        margin-bottom: 0.5rem;
    }

    &:hover {
        background: ${({ theme }) => theme.colors.primary.lightGrey.color};
    }
`;

const Options = styled.ul<{ isOpen: boolean }>`
    position: absolute;
    top: 95%;
    left: 0;
    right: 0;
    opacity: ${({ isOpen }) => (isOpen ? `1` : `0`)};
    transition: opacity ${({ isOpen }) => (isOpen ? `200ms` : `0ms`)};
    z-index: 2;

    margin: 0;
    padding: 0;
    border-radius: 3px;
    overflow: hidden;
    background: white;
    border: solid 1px ${({ theme }) => theme.colors.primary.grey.color};
    list-style: none;
    background: white;
    max-height: 200px;
    overflow-y: auto;

    ${Option}:not(:last-child) {
        border-bottom: solid 1px
            ${({ theme }) => theme.colors.primary.lightGrey.color};
    }
`;

export const SearchableSelect: React.FC<SearchableSelectProps> = ({
    options,
    label,
    id,
    placeholder,
    handleSelect,
    error,
}) => (
    <Downshift
        onChange={(selection) => handleSelect(selection)}
        itemToString={(item) => (item ? item.label : '')}
    >
        {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getRootProps,
            getMenuProps,
            getToggleButtonProps,
            isOpen,
            inputValue,
            selectedItem,
        }) => (
            <Container {...getRootProps()}>
                {error && (
                    <InfoMessage data-testid={`${id}Error`}>
                        {error}
                    </InfoMessage>
                )}
                <Control hasItemSelected={!!selectedItem} error={!!error}>
                    <label {...getLabelProps({ htmlFor: id })}>{label}</label>
                    <input {...getInputProps({ id, placeholder })} />
                    <button
                        {...getToggleButtonProps()}
                        data-testid={`${id}Button`}
                    >
                        <DropDownIcon />
                    </button>
                </Control>
                <Options {...getMenuProps()} isOpen={isOpen}>
                    {isOpen &&
                        options
                            .filter(
                                (item) =>
                                    !inputValue ||
                                    item.label.includes(inputValue)
                            )
                            .map((item, index) => (
                                <Option
                                    {...getItemProps({
                                        key: item.value,
                                        index,
                                        item,
                                        isSelected:
                                            selectedItem &&
                                            selectedItem.value === item.value,
                                    })}
                                    data-testid={`option${item.label.replace(
                                        ' ',
                                        ''
                                    )}`}
                                >
                                    <small>RESIDENCE</small>
                                    {item.label}
                                </Option>
                            ))}
                </Options>
            </Container>
        )}
    </Downshift>
);
