import initFirebase, { auth } from './firebase';
import 'firebase/firestore';

export const loginUser = async (email: string, password: string) =>
    await auth.signInWithEmailAndPassword(email, password);

export const getBuildingForUser = async (currentUser: firebase.User | null) => {
    if (!currentUser) {
        return;
    }

    const db: firebase.firestore.Firestore = initFirebase().firestore();
    const buildingSnapshot = await db
        .collection('buildings')
        .where('users', 'array-contains', currentUser.uid)
        .get();

    return {
        id: buildingSnapshot.docs[0].id,
        ...buildingSnapshot.docs[0].data(),
    } as parcel.IBuilding;
};

export const resetPassword = async (emailAddress: string) =>
    auth.sendPasswordResetEmail(emailAddress);
