import styled from '../../styled-components';
import { Link as UnstyledLink } from 'react-router-dom';
import { ButtonStyles } from '../../components/Form';

export const Link = styled(UnstyledLink)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary.dark.color};

    &:hover {
        text-decoration: underline;
    }
`;

export const ButtonLink = styled(UnstyledLink)`
    text-decoration: none;
    ${ButtonStyles}
`;
