import styled, { css } from '../../styled-components';
import { StylelessButton } from '../ui';

export const ButtonStyles = css<{ small?: boolean; disabled?: boolean }>`
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.darkGreen};
    color: #fff;
    padding: 1rem 1.3rem;
    text-align: left;
    font-size: 2rem;
    display: flex;
    line-height: 1.3;
    font-weight: 600;

    transition: background 300ms, opacity 300ms;

    svg {
        margin-left: 1.8rem;
    }

    ${({ small }) =>
        small &&
        css`
            padding: 0.4rem 1.3rem;
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;

export const Button = styled(StylelessButton)`
    ${ButtonStyles}
`;
