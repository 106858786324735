import React, { useContext, useState } from 'react';
import { visitorsApi } from '../../api';
import { BuildingContext } from '../../context/BuildingContext';
import useAsyncEffect from 'use-async-effect';
import styled from '../../styled-components';
import { Button } from '../Form';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as UserCheckIcon } from '../../assets/icons/user-check.svg';
import { Loading } from '../ui';
import { SuccessMessage } from '../../ui-components';

enum FORM_STATE {
    pending,
    suceeded,
}

type SignOutVisitorFormProps = {
    onFinish: () => void;
};

const FormHint = styled.p`
    margin-bottom: 3.5rem;
`;

const NoVisitorsMessage = styled.div`
    border-radius: 3px;
    line-height: 1.3;
    background: #f2f2f2;
    border: solid 1px #bdbdbd;
    padding: 4rem 13rem;
    text-align: center;
`;

const SuccessView = styled.div`
    ${SuccessMessage} {
        margin-bottom: 2rem;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
    }
`;

const SignOutButtons = styled.div`
    button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1.5rem;
    }
`;

export const SignOutVisitorForm: React.FC<SignOutVisitorFormProps> = ({
    onFinish,
}) => {
    const building = useContext(BuildingContext);
    const [formState, setFormState] = useState<FORM_STATE>(FORM_STATE.pending);
    const [visitors, setVisitors] = useState<parcel.IVisitor[]>();
    const [visitorSignedOut, setVisitorSignedOut] = useState<parcel.IVisitor>();

    useAsyncEffect(async () => {
        const visitors = await visitorsApi.getCurrentVisitors(building.id);
        setVisitors(visitors);
    }, [building.id]);

    const signOutVisitor = (visitor: parcel.IVisitor) => {
        setVisitorSignedOut(visitor);

        try {
            visitorsApi.signOut(building.id, visitor);
            setFormState(FORM_STATE.suceeded);
        } catch (e) {
            throw e;
        }
    };

    return (
        <>
            {formState === FORM_STATE.pending && (
                <>
                    <FormHint>Click on your name to sign out.</FormHint>

                    {!visitors && <Loading />}

                    {visitors && visitors.length === 0 && (
                        <NoVisitorsMessage data-testid="noVisitorsMessage">
                            There are no visitors currently signed into the
                            building.
                        </NoVisitorsMessage>
                    )}

                    {visitors && visitors.length > 0 && (
                        <SignOutButtons>
                            {visitors.map((visitor, i) => (
                                <Button
                                    type="button"
                                    key={i}
                                    onClick={() => signOutVisitor(visitor)}
                                    data-testid={`signOutVisitor${visitor.name.replace(
                                        ' ',
                                        ''
                                    )}`}
                                >
                                    {visitor.name} <ArrowRightIcon />
                                </Button>
                            ))}
                        </SignOutButtons>
                    )}
                </>
            )}

            {formState === FORM_STATE.suceeded && visitorSignedOut && (
                <SuccessView>
                    <SuccessMessage>
                        Thanks {visitorSignedOut.name}, you’re all signed out
                        <UserCheckIcon />
                    </SuccessMessage>

                    <div className="actions">
                        <Button
                            type="button"
                            onClick={() => onFinish()}
                            data-testid="finishedSignOut"
                        >
                            Finished <ArrowRightIcon />
                        </Button>
                    </div>
                </SuccessView>
            )}
        </>
    );
};
