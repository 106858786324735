import { BaseApi } from './base-api';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

export interface IVisitorApiResource {
    create: (
        buildingId: string,
        visitor: Partial<parcel.IVisitor>
    ) => Promise<firebase.firestore.DocumentReference>;
    getCurrentVisitors: (buidlingId: string) => Promise<parcel.IVisitor[]>;
    signOut: (buildingId: string, visitor: parcel.IVisitor) => Promise<void>;
}

class VisitorsApi extends BaseApi implements IVisitorApiResource {
    public create(
        buildingId: string,
        partialVisitor: Partial<parcel.IVisitor>
    ): Promise<firebase.firestore.DocumentReference> {
        const newVisitor = {
            ...partialVisitor,
            timeOfArrival: firebase.firestore.Timestamp.now(),
            timeOfDeparture: null,
        } as api.ICreateVisitorPayload;

        return this.db
            .collection(`buildings/${buildingId}/visitors`)
            .add(newVisitor);
    }

    public async getCurrentVisitors(
        buildingId: string
    ): Promise<parcel.IVisitor[]> {
        const visitorQueryResult = await this.db
            .collection(`buildings/${buildingId}/visitors`)
            .where('timeOfDeparture', '==', null)
            .get();

        return visitorQueryResult.docs.map(
            (doc) => ({ ...doc.data(), id: doc.id } as parcel.IVisitor)
        );
    }

    public signOut(
        buildingId: string,
        visitor: parcel.IVisitor
    ): Promise<void> {
        return this.db
            .collection(`buildings/${buildingId}/visitors`)
            .doc(visitor.id)
            .set({
                ...visitor,
                timeOfDeparture: firebase.firestore.Timestamp.now(),
            });
    }
}

export const visitorsApi = new VisitorsApi();
