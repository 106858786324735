import React from 'react';
import styled from '../../styled-components';

interface TextInputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label: string;
    value: string;
    error?: string;
}

const Label = styled.label`
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.darkGreen};
    font-size: 1.6rem;
    font-weight: 600;
    display: block;
    margin-bottom: 1rem;
`;

const Input = styled.input`
    padding: 0.5rem 0;
    border: 0;
    border-bottom: solid 1px ${({ theme }) => theme.colors.darkGreen};
    width: 100%;
    transition: border-color 200ms;

    &:focus {
        outline: none;
    }
`;

const Error = styled.div`
    font-weight: normal;
    position: absolute;
    right: 0;
    top: 0;
    color: ${({ theme }) => theme.colors.negative.red.color};
`;

const UnstyledTextInput: React.FC<TextInputProps> = ({
    label,
    id,
    value,
    error,
    className,
    ...inputProps
}) => (
    <div className={className}>
        <Label htmlFor={id}>
            {label}{' '}
            {error && <Error data-testid={`${id}-error`}>({error})</Error>}
        </Label>

        <Input
            type="text"
            id={id}
            value={value}
            {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
        />
    </div>
);

export const TextInput = styled(UnstyledTextInput)`
    display: block;
    width: 100%;
    position: relative;
    font-size: 1.8rem;

    ${Input} {
        border-color: ${({ theme, error, value }) => {
            if (!!error) {
                return theme.colors.negative.red.color;
            } else if (value.length > 0) {
                return theme.colors.primary.blue.color;
            } else {
                return theme.colors.darkGreen;
            }
        }};

        &:focus {
            border-color: ${({ theme, error }) =>
                !!error
                    ? theme.colors.negative.red.color
                    : theme.colors.primary.blue.color};
        }
    }
`;
