import React, { useContext, useState, useEffect } from 'react';
import { Constrain, Loading } from '../../components/ui';
import { RouteComponentProps } from 'react-router';
import {
    Route,
    Switch,
    Redirect,
    LinkProps,
    Link as UnstyledLink,
} from 'react-router-dom';
import { residencesApi } from '../../api';
import { BuildingContext } from '../../context/BuildingContext';
import styled from '../../styled-components';
import { ParcelsTab } from './ParcelsTab';
import { ResidentsTab } from './ResidentsTab';
import {
    PageWrapper,
    Link,
    HeadingLevel2,
    HeadingLevel3,
} from '../../ui-components';
import { ReactComponent as ChevronRightSVG } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as ResidencesSVG } from '../../assets/icons/residences.svg';

const TabNav = styled.div`
    display: flex;
    background: white;
    border-radius: 0.4rem;
    overflow: hidden;
`;

const UnstyledTab: React.FC<LinkProps & { activeTab: boolean }> = ({
    activeTab,
    children,
    ...rest
}) => <UnstyledLink {...rest}>{children}</UnstyledLink>;

const Tab = styled(UnstyledTab)`
    width: 150px;
    text-align: center;
    padding: 1.2rem 2rem;
    text-decoration: none;
    font-weight: 600;
    background: ${({ activeTab, theme }) =>
        activeTab ? theme.colors.primary.dark.color : 'white'};
    color: ${({ activeTab, theme }) =>
        activeTab ? 'white' : theme.colors.primary.dark.color};
    transition: 200ms background;
`;

const BackLink = styled(Link)`
    display: inline-flex;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.primary.dark.color};
    font-weight: bold;

    img {
        width: 8px;
        margin-left: 1rem;
        transform: rotate(180deg);
    }
`;

const Breadcrumb = styled.div`
    display: flex;
    align-items: center;

    .heading {
        display: flex;

        svg {
            margin-right: 1rem;
        }
    }

    & > svg {
        margin: 0 0.5rem;
    }
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    align-items: flex-start;
`;

const Residences = ({
    match,
    location,
}: RouteComponentProps<{ residenceId: string }>) => {
    const building = useContext(BuildingContext);
    const [residence, setResidence] = useState<parcel.IResidence | null>(null);

    useEffect(() => {
        const getResidence = async () => {
            const residenceResult = (await residencesApi.getById(
                building.id,
                match.params.residenceId
            )) as parcel.IResidence;

            setResidence(residenceResult);
        };

        getResidence();
    }, [building.id, match.params.residenceId]);

    const { path, url } = match;
    const { pathname } = location;

    return (
        <>
            {!residence && <Loading />}

            {residence && (
                <PageWrapper>
                    <Constrain>
                        <Header>
                            <Breadcrumb>
                                <BackLink to="/residences">
                                    <HeadingLevel2
                                        bold
                                        className="heading"
                                        data-testid="parcelsHeader"
                                    >
                                        <ResidencesSVG />
                                        Residences
                                    </HeadingLevel2>
                                </BackLink>

                                <ChevronRightSVG />

                                <HeadingLevel3>
                                    {residence.nameOrNumber}
                                </HeadingLevel3>
                            </Breadcrumb>

                            <TabNav>
                                <Tab
                                    to={`${url}/parcels`}
                                    activeTab={pathname === `${url}/parcels`}
                                >
                                    Parcels
                                </Tab>
                                <Tab
                                    to={`${url}/residents`}
                                    activeTab={pathname === `${url}/residents`}
                                >
                                    Residents
                                </Tab>
                            </TabNav>
                        </Header>

                        <Switch>
                            <Route
                                exact
                                path={`${path}/parcels`}
                                component={ParcelsTab}
                            />
                            <Route
                                exact
                                path={`${path}/residents`}
                                component={ResidentsTab}
                            />

                            <Redirect to={`${path}/parcels`} />
                        </Switch>
                    </Constrain>
                </PageWrapper>
            )}
        </>
    );
};

export default Residences;
