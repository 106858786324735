import { ISearchSelectItem } from './SearchSelect.component';

export function mapResidencesToSelectItems(
    residences: parcel.IResidence[]
): ISearchSelectItem[] {
    return residences.map(({ nameOrNumber, id }) => {
        return {
            value: id,
            label: nameOrNumber,
        };
    });
}
