import React, { useContext, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Formik } from 'formik';
import { visitorsApi, residencesApi } from '../../api';
import { BuildingContext } from '../../context/BuildingContext';
import {
    TextInput,
    SearchableSelect,
    SearchableSelectItem,
    Button,
} from '../Form';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as UserCheckIcon } from '../../assets/icons/user-check.svg';
import * as Yup from 'yup';
import styled from '../../styled-components';
import { FormRow } from '../ui';
import { SuccessMessage } from '../../ui-components';

const INITIAL_VALUES = {
    name: '',
    visiting: '',
    company: '',
};

enum FORM_STATE {
    pending,
    suceeded,
}

interface SignInFlatVisitorFormProps {
    onFinish: () => void;
}

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Please enter your full name')
        .max(300, 'Name is too long.')
        .required('Your full name is required'),
    visiting: Yup.string().required(
        "You must select which flat you're visiting, close this form if you wish to sign in as a visitor to the building"
    ),
});

const Input = styled(TextInput)`
    font-size: 2rem;
`;

const FormActions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SuccessView = styled.div`
    ${SuccessMessage} {
        margin-bottom: 2rem;
    }

    .actions {
        display: flex;
        justify-content: space-between;
    }
`;

function mapResidenceToOptions(
    residences: parcel.IResidence[]
): SearchableSelectItem[] {
    return residences.map((residence) => ({
        value: residence.id,
        label: residence.nameOrNumber,
    }));
}

export const SignInFlatVisitorForm: React.FC<SignInFlatVisitorFormProps> = ({
    onFinish,
}) => {
    const building = useContext(BuildingContext);
    const [formState, setFormState] = useState<FORM_STATE>(FORM_STATE.pending);
    const [residences, setResidences] = useState<parcel.IResidence[]>([]);
    const [visitorName, setVisitorName] = useState<string>();

    useAsyncEffect(async () => {
        const fetchedResidences = await residencesApi.get(building.id);
        setResidences(fetchedResidences);
    }, [building.id]);

    return (
        <>
            {formState === FORM_STATE.pending && (
                <Formik
                    initialValues={INITIAL_VALUES}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            await visitorsApi.create(building.id, values);
                            setVisitorName(values.name);
                            setFormState(FORM_STATE.suceeded);
                        } catch (err) {
                            console.error(err);
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched,
                        setFieldValue,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            data-testid="signInFlatVisitorForm"
                        >
                            <FormRow>
                                <SearchableSelect
                                    label="Select flat"
                                    placeholder="Search for a flat"
                                    id="visiting"
                                    handleSelect={({ value }) =>
                                        setFieldValue(
                                            'visiting',
                                            residences.find(
                                                (r) => r.id === value
                                            )
                                        )
                                    }
                                    options={mapResidenceToOptions(residences)}
                                    error={
                                        (touched.visiting && errors.visiting) ||
                                        undefined
                                    }
                                />
                            </FormRow>

                            <FormRow>
                                <Input
                                    label="Full name"
                                    id="name"
                                    name="name"
                                    value={values.name}
                                    placeholder="Enter your full name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        (touched.name && errors.name) ||
                                        undefined
                                    }
                                />
                            </FormRow>

                            <FormRow>
                                <Input
                                    label="Company"
                                    id="company"
                                    name="company"
                                    value={values.company}
                                    placeholder="Enter your company name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormRow>

                            <FormActions>
                                <Button
                                    type="submit"
                                    data-testid="signInVisitor"
                                >
                                    Sign in <ArrowRightIcon />
                                </Button>
                            </FormActions>
                        </form>
                    )}
                </Formik>
            )}

            {formState === FORM_STATE.suceeded && (
                <SuccessView>
                    <SuccessMessage>
                        Thanks {visitorName}, you’re signed in. Don’t forget to
                        sign out when leaving
                        <UserCheckIcon />
                    </SuccessMessage>

                    <div className="actions">
                        <Button
                            type="button"
                            onClick={() => {
                                setFormState(FORM_STATE.pending);
                                setVisitorName(undefined);
                            }}
                            data-testid="signInAnotherVisitor"
                        >
                            Sign in another visitor <ArrowRightIcon />
                        </Button>

                        <Button
                            type="button"
                            onClick={() => onFinish()}
                            data-testid="finishedSignIn"
                        >
                            Finished <ArrowRightIcon />
                        </Button>
                    </div>
                </SuccessView>
            )}
        </>
    );
};
