import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from './styled-components';
import theme from './theme';
import GlobalStyles from './globalStyles';
import * as Sentry from '@sentry/browser';
import config from './config';

if (config.SENTRY_DSN.length) {
    Sentry.init({
        dsn: config.SENTRY_DSN,
    });
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <>
            <GlobalStyles />

            <App />
        </>
    </ThemeProvider>,
    document.getElementById('app-element')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
