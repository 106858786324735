import { BaseApi } from './base-api';
import { firestore } from 'firebase/app';

export interface IResidencesApiResource {
    get: (buildingId: string) => Promise<parcel.IResidence[]>;
    getById: (
        buildingId: string,
        residenceId: string
    ) => Promise<parcel.IResidence | undefined>;
    updateNotificationPreference: (
        updatedResidence: parcel.IResidence,
        buildingId: string
    ) => Promise<void>;
    addResident: (
        buildingId: string,
        residenceId: string,
        resident: parcel.IResident
    ) => Promise<void>;
    removeResident: (
        buildingId: string,
        residenceId: string,
        resident: parcel.IResident
    ) => Promise<void>;
    updateResident: (
        buildingId: string,
        residenceId: string,
        previousResident: parcel.IResident,
        updateResident: parcel.IResident
    ) => Promise<[void, void]>;
}

class ResidencesApi extends BaseApi implements IResidencesApiResource {
    public async get(buildingId: string): Promise<parcel.IResidence[]> {
        const doc = await this.db
            .collection(`buildings/${buildingId}/residences`)
            .orderBy('seqNo', 'asc')
            .get();
        let residences: parcel.IResidence[] = [];

        doc.forEach((docRef) =>
            residences.push({
                id: docRef.ref.id,
                ...docRef.data(),
            } as parcel.IResidence)
        );

        return residences;
    }

    public async getById(
        buildingId: string,
        residenceId: string
    ): Promise<parcel.IResidence | undefined> {
        const residenceSnapshot = await this.db
            .collection(`buildings/${buildingId}/residences`)
            .doc(residenceId)
            .get();

        return (residenceSnapshot && {
            id: residenceSnapshot.id,
            ...residenceSnapshot.data(),
        }) as parcel.IResidence | undefined;
    }

    public async updateNotificationPreference(
        updatedResidence: parcel.IResidence,
        buildingId: string
    ): Promise<void> {
        const { id, ...backendResidence } = updatedResidence;

        return this.db
            .collection(`buildings/${buildingId}/residences`)
            .doc(id)
            .set(backendResidence);
    }

    public async addResident(
        buildingId: string,
        residenceId: string,
        resident: parcel.IResident
    ): Promise<void> {
        return this.db
            .collection(`buildings/${buildingId}/residences`)
            .doc(residenceId)
            .update({
                residents: firestore.FieldValue.arrayUnion(resident),
            });
    }

    public async removeResident(
        buildingId: string,
        residenceId: string,
        resident: parcel.IResident
    ): Promise<void> {
        return this.db
            .collection(`buildings/${buildingId}/residences`)
            .doc(residenceId)
            .update({
                residents: firestore.FieldValue.arrayRemove(resident),
            });
    }

    public async updateResident(
        buildingId: string,
        residenceId: string,
        previousResident: parcel.IResident,
        updatedResident: parcel.IResident
    ): Promise<[void, void]> {
        return Promise.all([
            this.removeResident(buildingId, residenceId, previousResident),
            this.addResident(buildingId, residenceId, updatedResident),
        ]);
    }
}

export const residencesApi = new ResidencesApi();
