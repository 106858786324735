import React from 'react';
import styled from '../../styled-components';

const UnstyledMessage: React.FC<parcel.IBaseStyledComponent> = ({
    className,
    children,
}) => (
    <div className={className} data-testid="message">
        {children}
    </div>
);

export const ErrorMessage = styled(UnstyledMessage)`
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.negative.red.color};
    color: white;
    padding: 1.4rem 2.2rem;
    display: flex;
    align-items: flex-start;
    font-size: 2rem;
    line-height: 1.3;
    min-height: 80px;
    flex-direction: column;

    svg {
        flex: 0 0 auto;
        margin-left: 2rem;
        align-self: flex-end;
    }
`;
